import React from "react";
import { HashLink } from 'react-router-hash-link';

export const faqItemsStudyHelp1 = [
  {
    Q: "How do you select an expert for me?",
    A: <>Our customer care managers select the best-matching expert available to help you with your request. The managers take into consideration freelancers major, experience, rating, and various additional parameters to make sure the expert perfectly meets your requirements.</>
  },
  {
    Q: <>What requests can your experts help me with?</>,
    A: <>Our freelancers are all skilled to help you with any subject unless your request is illegal or violates any college policies. Please read our <HashLink className="link" to="/honor-code">Honor Code</HashLink> and the <HashLink className="link" to="/honor-code#list">list of prohibited requests</HashLink> for details.</>
  },
  {
    Q: <>How quickly can I get help?</>,
    A: <>We provide help 24/7 including the weekends and will quickly respond to your inquiry at any time of the day. However, we strongly advise you to send a help request at least 3 hours before the due time you want to get assistance. This is just to make sure our experts have enough time to clarify all the details and help you in the best possible way.</>
  },
  {
    Q: <>How can I be sure that my money is safe?</>,
    A: <>You can submit the prepayment with any Credit/Debit card by following the invoice link we send you after confirming your request. The link automatically takes you to a 128-bit encrypted HTTPS payment page. We have no access to your CC data or your private info.</>
  },
];
