export const feedbackItemsBusiness = [
  [
    {
      type: "A",
      message: "Hey, Alex! How was your last task?",
    },
    {
      type: "C",
      message: "Great, Jamie is truly the best!",
    },
    {
      type: "C",
      message: "I was REALLY struggling with my marketing presentation but he was so patient when explaining all the concepts.",
    },
    {
      type: "A",
      message: "Glad to hear that! Thanks for your feedback 😊",
    },
  ],

  [
    {
      type: "C",
      message: "Your expert seriously rocked in helping me tackle that project for my business class. They made those tricky number games feel easy. Finally!!",
    },
    {
      type: "C",
      message: "Major props to your experts for their awesome help",
    },
  ],

  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],

  [
    {
      type: "A",
      message: "Hello there, did the expert help you with the task you're stuck on?",
    },
    {
      type: "C",
      message: "Absolutely! In less than an hour, he managed to get me on the right track for my Marketing research and also helped with drafting the questions for a survey. 🔥🔥 I doubted that we'd have enough time but I am very satisfied with the outcome.",
    },
  ],

  [
    {
      type: "A",
      message: "Hello there, hope you enjoyed your session. How did you like the help you received from your assigned expert? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "5, ofc. Nick became a guiding light for the management assignment I am working on. I felt so confused, but he just navigated me like a pro. Big thanks!",
    },
  ],

  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],

  [
    {
      type: "C",
      message: "Can you assign me to Colleen again pls?",
    },
    {
      type: "C",
      message: "She was so good at guiding me through my assignment for my Business class.",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],

  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],

  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
