import React from 'react';
import {NavLink} from 'react-router-dom'
import ContentPage from '../';
import { REACT_APP_CONTACT_EMAIL } from '../../../../../config';
//import LocalesPage from './../LocalesPage';

export default () => (
    <ContentPage>
      <h1 className="content-title">Refund Policy</h1>

      <p className="content-p">The Awesome Tutors platform connects a student and a tutor to offer specific services, such as tutoring on various topics and subjects as well as helping with research, surveys, analysis, giving consultations, etc.</p>

      <h2 className="content-p">What should you do if the service you received did not meet your initial requirements?</h2>

      <p className="content-p">You must send a refund request within 60 days of receiving the service. If the service you received did not meet your expectations, please let us know. Send us a note along with your request number and any further comments at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>.</p>

      <p className="content-p">It will take up to 3-5 business days to review your request. Upon further agreement with you, we will either find another tutor for you free of charge, or we will make a refund to your card or balance. Balance money on your Awesome Tutors personal account (virtual bonus money used by Awesome Tutors) is non-refundable.</p>

      <div className="content-h2">Refund of your subscription</div>
      <p className="content-p">You can request a refund of a subscription fee charged on your card up within 30 days from the date of the last payment. The refund can be issued only if you have not used bonuses and benefits included in the subscription plan since the last payment. In this case, your refund request will be processed within 24 hours and you will receive the money back on your card within 3-5 business days. Please be aware that the bonuses and benefits included in your subscription plan are non-refundable and will be canceled on the date of the refund.</p>

  </ContentPage>
)
