import React from 'react';
import ContentPage from '../';
import { REACT_APP_CONTACT_EMAIL } from '../../../../../config';
//import { NavLink } from 'react-router-dom';

export default () => (
  <ContentPage>
    <h1 className="content-title">Privacy Policy</h1>

    <h2 className="content-h2">1. Intro</h2>
    <p className="content-p">You can learn more about how the Awesome Tutors platform gathers, manages, processes, and ultimately makes use of your personal information in this section. The Privacy Policy section may occasionally be amended due to the ongoing evolution of privacy legislation. The most recent revision to our privacy policy was made on July 14, 2023, and it will be effective for all uses of the Awesome Tutors platform website and services beginning on that date and moving forward. The personal information you give to third parties is not covered by this Privacy Policy.</p>

    <div className="content-h2">2. Collection of Information</div>
    <p className="content-p">At Awesome Tutors we collect such personal information as your phone number because this data is crucial for providing our services to you. In case you prefer not to share this information with us, you can still check our website but you will not be able to get access to these services. The data we collect, store, process, and use can be divided into three subtypes: the data you decide to provide to us directly, the information we collect automatically, and the information we receive from other sources.</p>

    <div className="content-h2">2.1. Information you provide directly</div>
    <p className="content-p">When you fill in the form or contact us directly through any chosen way of communication, you may specify personal details, for example, your email address, phone number, level of education, photos and any other information you decide to share with us.</p>

    <div className="content-h2">2.2. Information we collect automatically</div>
    <p className="content-p">When you use Awesome Tutors website, the following information is collected automatically:</p>
    <ul className="content-ul">
      <li>Data about your desktop or mobile device you use to access the website, such as device hardware model, operating system version, and screen resolution;</li>
      <li>Log information that includes your IP address, browser version, the configuration of the app, time, date, duration, and frequency of your visits to the website use as well as pages you accessed during them.</li>
    </ul>

    <p className="content-p">The above-mentioned information is gathered with the help of cookies, pixels, and other tracking devices. Learn more about the types of cookies we use in our Cookie Policy.</p>

    <div className="content-h2">2.3. Information we obtain from other sources</div>
    <p className="content-p">We may collect information about you from the third-party services such as social media websites only in case you gave those third-party services your consent to share this information with us. It is vital for providing our free and paid services to you.</p>

    <div className="content-h2">2.4. Sensitive information</div>
    <p className="content-p">We do not gather any sensitive information about you. We strongly encourage you not to provide this data to us. Sensitive information can include your race, ethnicity, gender, health condition, philosophical and religious beliefs, political views, and the like. If you decide to give this information to other website users, the Awesome Tutors platform does not take any responsibility for it.</p>

    <div className="content-h2">2.5. Children online privacy protection</div>
    <p className="content-p">The Awesome Tutors platform is not meant for usage by individuals under eighteen (18) years old. We will not intentionally gather, save and use information about such individuals.</p>

    <div className="content-h2">3. Use of Information</div>
    <p className="content-p">The information you share with us is used in order to improve, operate and protect the Awesome Tutors platform as well as to communicate with you and give you access to our paid and free services.</p>

    <div className="content-h2">3.1. Using your data for Awesome Tutors website operation</div>
    <p className="content-p">Improving the Awesome Tutors platform implies the following:</p>
    <ul className="content-ul">
      <li>Increase of the website performance;</li>
      <li>Processing transactions;</li>
      <li>Development of the new products and services;</li>
      <li>Monitoring and analysis of your activity to create the best experience for all website users;</li>
      <li>Detection, prevention, and investigation of the fraudulent cases;</li>
      <li>Prevention of the website abuse and activities that are illegal or violate our Terms of Use, Privacy Policy, and other policies;</li>
      <li>Protection of the rights and safety of all website users;</li>
      <li>Conduction of the statistical analysis and performance of other administrative tasks.</li>
    </ul>

    <div className="content-h2">3.2. Using your data for communicating with you</div>
    <p className="content-p">Your personal data is needed to:</p>
    <ul className="content-ul">
      <li>Provide customer service and technical support;</li>
      <li>Answer your questions;</li>
      <li>Update you with the recent news or alerts;</li>
      <li>Organize surveys.</li>
    </ul>

    <div className="content-h2">3.3. Advertising and other uses</div>
    <p className="content-p">Your personal information can also be used for advertising purposes, such as:</p>
    <ul className="content-ul">
      <li>Providing you with the personalized content and website features;</li>
      <li>Promoting the content that may be applicable to you.</li>
    </ul>

    <div className="content-h2">3.4. Legal basis for processing your information</div>
    <p className="content-p">Your personal information is gathered and used legally. The legal basis for it depends on the context in which we receive your data.</p>
    <ul className="content-ul">
      <li>The legal basis for storing your phone number is that this information is inevitable for providing you with the free and paid website features;</li>
      <li>Collection and processing information about you might be needed in order to comply with the legal requirements such as fraud prevention, network improvement and database safety, to protect your interests and interests and privacy of other website users.</li>
      <li>Gathering your personal information is necessary for improving your experience with the website.</li>
    </ul>

    <div className="content-h2">4. Sharing information and Affiliate Disclosure</div>
    <p className="content-p">Information about you can be shared with our third-party service providers and authorities in order to comply with the legal obligations and to protect our legal rights and property. Data obtained through the short code program will not be shared with any third-parties for their marketing reasons/purposes.</p>

    <div className="content-h2">4.1. Sharing information with the service providers</div>
    <p className="content-p">Third-party services are used for website and mobile application improvement and effective performance. These services help us operate the payments, send emails and text messages, host our data, control our ads, and give technical support. We give these services the right to use your personal information for their intended purposes, however, we make sure that this usage does not go beyond our authorization.</p>

    <div className="content-h2">4.2. Transfer of information to other countries</div>
    <p className="content-p">Your personal information may be transferred to other countries as these countries may have data protection regulation laws that vary from your home country. Data protection measures that apply to such transfers have been taken to provide the correspondence with the requirements of this Privacy Policy.</p>

    <div className="content-h2">4.3. Sharing information for security reasons</div>
    <p className="content-p">Your personal information is stored securely and it is protected against illegal usage. Our employees are trained in correspondence with this and other data protection policies. Only authorized individuals have access to use your data. Moreover, we constantly monitor our hardware and software for potential vulnerabilities.</p>

    <div className="content-h2">4.4. Affiliate Disclosure</div>
    <p className="content-p">Some links in our email campaign may be affiliate links. Affiliate links are developed to provide a means for other sites to earn advertising fees. The final cost will be the same whether you use the affiliate link. But as a member of an affiliate program, awesome-tutors.com will earn a commission if you purchase any service through an affiliate link.</p>

    <div className="content-h2">5. Your Data Rights</div>
    <p className="content-p">Your data rights consist of:</p>

    <ul className="content-ul">
      <li>The right to check, modify and request to delete your information. You can do it by contacting us at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>.</li>
      <li>The right to restrict the processing of your data. If you want to be excluded from the segmentation for communication and other purposes, please, message us at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>.</li>
      <li>The right to transfer your information. If you want to review all the information we have about you, contact us at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>. and we will send you a data file.</li>
      <li>The right to unsubscribe from marketing notifications. You can do that by clicking the unsubscribe link in emails you receive from us or by messaging us directly to <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>.</li>
      <li>The right to withdraw your permission to process your personal information at any time.</li>
      <li>The right to complain to your local Data Protection Authority (DPA) about our usage of your data.</li>
      <li>The right to opt out of our cookies usage and other tracking beams. Consult our Cookie Policy to find out how you can restrict our usage of cookies.</li>
    </ul>

  </ContentPage>
)
