import React from 'react';

import classnames from 'classnames';
import Overlay from '../Overlay';
import './index.css';

export default ({ type, className, onClick, children }) => (
  <Overlay>
    <div
      className={classnames('modal', {[`modal_${type}`]:type}, className )}
      onClick={onClick}
    >
      {type === 'logo' && <img
        className="modal-logo"
        width="auto"
        height="28"
        src={require('../../../assets/img/logo.svg')}
        alt=""/>
      }
      {children}
    </div>
  </Overlay>
);
