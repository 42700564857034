import React, { useEffect } from 'react';
import MainModal from '../MainModal';
//import classnames from 'classnames';
//import { useMobile } from '../../../../hooks';

import './report-modal.css';


export const ReportAbuseModal = ({onClose}) => {

  useEffect(() => {
    //document.querySelector('html').classList.add('fixed');
  });

  function onModalClose() {
    document.querySelector('html').classList.remove('fixed');
    onClose();
  }

  return (
    <MainModal className={'report-modal'}>
      <img
        className="report-img"
        width="48" height="48"
        src={require('./img/thumb-img.svg')}
        alt=""/>
      <div className="report-title">Thank you for reporting!</div>
      <div className="report-message">We will review your request and get back to you in 3-5 business days!</div>
      <button className="link-button4 type2" onClick={onModalClose}>Got it</button>
    </MainModal>
  );
};
