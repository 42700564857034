import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import Holder from '../../components/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import TextBackBlock2 from '../../blocks/TextBackBlock2';
import Note from '../../blocks/Note';

import { ThankYouModal } from '../../blocks/Modal/ThankYouModal';

import LocalesPage from '../LocalesPage';

import { useMobile } from '../../../../hooks';

import './top-tutors.css';

const tutorItems = [
  {
    name: "Tim H.",
    img: "Tim_H",
    degree: "Master's",
    helpsWith: "Statistics",
    sessions: "1340",
    tutoringExperience: "6 years",
    greeting: "I can provide you with the assistance you need to attain proficiency in the subject and secure excellent results. I will show you how to interpret data and learn from it. I teach since 2017 and I am also a very friendly and patient person."
  },
  {
    name: "Nick C.",
    img: "Nick_C",
    degree: "Master's",
    helpsWith: "Business, Management",
    sessions: "1790",
    tutoringExperience: "4 years",
    greeting: "I have a master's degree in business administration and I love teaching. I am a highly motivated, passionate and experienced tutor who has expertise in all types of business-related topics and tasks. I will teach you what's important!"
  },
/*
  {
    name: "Lynn O.",
    img: "Lynn_O",
    degree: "PhD",
    helpsWith: "Psychology",
    sessions: "1404",
    tutoringExperience: "8+ years",
    greeting: "Being a psychologist is my passion and teaching is something I always enjoyed. I am excited to share my knowledge with students both on campus and online, and I am in this field for more than 10 years now."
  },
*/
  {
    name: "Simon E.",
    img: "Simon_E",
    degree: "Master's",
    helpsWith: "Algebra, Geometry, Calculus, Trigonometry",
    sessions: "973",
    tutoringExperience: "5 years",
    greeting: "I am an experienced math tutor with more than 3000 hours of online lessons taught including those on Awesome Tutors. I have developed a deep understanding of how to teach various math subjects so you can take the most out of the tutoring session."
  },
  {
    name: "Ali B.",
    img: "Ali_B",
    degree: "PhD",
    helpsWith: "Physics",
    sessions: "1288",
    tutoringExperience: "3 years",
    greeting: "I love physics, and I like to pass on my passion. In addition to having a PhD in experimental nano-physics, I have four years of tutoring experience (including online) along with experimental research. I will be a perfect instructor for you!"
  },
  {
    name: "Ricky P.",
    img: "Ricky_P",
    degree: "PhD",
    helpsWith: "Finance, Accounting, Banking",
    sessions: "1501",
    tutoringExperience: "7 years",
    greeting: "I specialize in teaching finance-related courses such as corporate finance, financial mathematics, investments, and others. I approach tutoring on a case-by-case basis as every student has different needs and expectations."
  },

  {
    name: "Mina M.",
    img: "Mina_M",
    degree: "Master's",
    helpsWith: "International Politics, Business studies",
    sessions: "704",
    tutoringExperience: "3 years",
    greeting: "I know how to organize tutoring sessions on Business and management studies in an interesting and easy way. I encourage and appreciate student goals and passion, and offer new and innovative ways to exchange experiences and improve skills."
  },
  {
    name: "LJ Y.",
    img: "LJ_Y",
    degree: "Master's",
    helpsWith: "Healthcare",
    sessions: "988",
    tutoringExperience: "5 years",
    greeting: "A dedicated and highly skilled tutor with a degree in Medical Technology. With my strong educational background and passion for healthcare, I can help you with any topic! I am a deeply passionate teacher with kind heart and bright personality."
  },
  {
    name: "Ethan S.",
    img: "Ethan_S",
    degree: "Master's",
    helpsWith: "Chemistry, Biology, Healthcare",
    sessions: "1211",
    tutoringExperience: "3 years",
    greeting: "I have always been passionate about nerdy knowledge and science, and that is how I ended up studying for a Master of Science degree in Chemistry. I am always searching for new opportunities to grow my knowledge in teaching as courses or volunteering."
  },
  {
    name: "Savannah D.",
    img: "Savannah_D",
    degree: "PhD",
    helpsWith: "History",
    sessions: "1643",
    tutoringExperience: "6 years",
    greeting: "Having got a PhD and having 6 years of experience in tutoring, I always manage to find just the right individual approach and meet anyone's needs in history learning. I value and respect students and their opinions."
  },
  {
    name: "Nicole T.",
    img: "Nicole_T",
    degree: "Master's",
    helpsWith: "English, English Literature",
    sessions: "905",
    tutoringExperience: "7 years",
    greeting: "Hey, dear future students! I'm here to help you explore and analyze the captivating world of literary works. I'll guide you in understanding themes, characters, and writing techniques employed by renowned authors."
  },
  {
    name: "Amir J.",
    img: "Amir_J",
    degree: "PhD",
    helpsWith: "Philosophy",
    sessions: "736",
    tutoringExperience: "11 years",
    greeting: "I am an experienced private tutor specializing in mathematical philosophy and general philosophy. My passion lies in unraveling the intricacies of logical reasoning and abstract concepts and instilling critical thinking."
  },
  {
    name: "Veronica R.",
    img: "Veronica_R",
    degree: "Master's",
    helpsWith: "Sociology",
    sessions: "1102",
    tutoringExperience: "4 years",
    greeting: "I'm passionate about sharing knowledge and adding value to people which fuels my desire to teach. I love to do research and make analyses, and believe me, there’s a wide range of studies that comes under this umbrella statement."
  },
  {
    name: "Jose W.",
    img: "Jose_W",
    degree: "Master's",
    helpsWith: "Economics",
    sessions: "601",
    tutoringExperience: "3 years",
    greeting: "I am a professional tutor and consultant having extensive international experience delivering strategic direction across business units. I am also an expert in risk and investment management and financial analysis."
  },
  {
    name: "Katherine M.",
    img: "Katherine_M",
    degree: "Master's",
    helpsWith: "English",
    sessions: "1872",
    tutoringExperience: "8 years",
    greeting: "Teaching English and creative writing is my true and sincere love, my passion and soul vocation, constant discoveries, and personal fulfillment. I have 8 years of professional teaching experience and this has been so fun!"
  },
  {
    name: "Andrea C.",
    img: "Andrea_C",
    degree: "PhD",
    helpsWith: "Law",
    sessions: "837",
    tutoringExperience: "12 years",
    greeting: "I used to work in the international law firm and I communicated with individuals and lawyers from all over the world! During this time I have been lecturing classes and re-discovered myself as an enthusiastic educator, so I switched to teaching full-time."
  },
  {
    name: "Sean F.",
    img: "Sean_F",
    degree: "Master's",
    helpsWith: "Finance, Economics",
    sessions: "978",
    tutoringExperience: "4 years",
    greeting: "My strengths are corporate finance, microeconomics, macroeconomics, and financial accounting. I am extremely enthusiastic about teaching. Personally, I see myself as a life-long learner who enjoys exploring thoughts and ideas when it comes to education."
  },
  {
    name: "Justin N.",
    img: "Justin_N",
    degree: "Master's",
    helpsWith: "Marketing",
    sessions: "1650",
    tutoringExperience: "3 years",
    greeting: "I'm a professional marketer with industry experience who can help you to learn complex management and marketing theories in a simple manner. I'm also well-versed in business management, international business, global marketing and HRM."
  },
/*
  {
    name: "Vincent V.",
    img: "https://prnt.sc/bhYRiib0c4Xq",
    degree: "Master's",
    helpsWith: "Data science",
    sessions: "899",
    tutoringExperience: "5+ years",
    greeting: "Over the years, I have helped many students to achieve their academic and career goals through personalized and engaging tutoring sessions. I began my teaching career in 2018, and since then, education has become my primary focus, but before that, I had experience as an analyst and data scientist at large IT companies."
  },
*/
  {
    name: "Jamie L.",
    img: "Jamie_L",
    degree: "Master's",
    helpsWith: "Biology",
    sessions: "1341",
    tutoringExperience: "7 years",
    greeting: "I love to teach and have been working in international schools across the world for more than 7 years. I am thrilled to take my students along with me as we explore the wonders of biology. I believe the best tutor is the one who never stops being a student!"
  },
];

export default (props) => {
  const isMobile = useMobile();
  const [itemsToShow, setItemsToShow] = useState(3);
  const [visibleItems, setVisibleItems] = useState(itemsToShow);

  const showMoreItems = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + itemsToShow);
  };

  useEffect(() => {
    setItemsToShow(isMobile ? 3 : 6);
    setVisibleItems(isMobile ? 3 : 6);
  }, [isMobile]);

  const { pageCfg } = props;

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="tutors-hero">
          <Holder type={1} className={'tutors-hero-holder'}>
            <div className="tutors-hero-column">
              <div className="tutors-hero-block">
                <h1 className="tutors-hero-title">Meet our rockstars!</h1>
                <h2 className="tutors-hero-subtitle">Although all our tutors are awesome, these experts have particularly impressed us over recent months.</h2>
              </div>
              {isMobile ?
                <img
                  className="mobile"
                  width="100%"
                  height="auto"
                  src={require('./img/top3.png')}
                  srcSet={require('./img/top3@2x.png') + ' 2x'}
                  alt=""/>
                :
                <img
                  className="desktop"
                  width="388"
                  height="159"
                  src={require('./img/top2.png')}
                  srcSet={require('./img/top2@2x.png') + ' 2x'}
                  alt=""/>
              }
            </div>
            {!isMobile &&
            <img
              className="desktop"
              width="808"
              height="388"
              src={require('./img/top1.png')}
              srcSet={require('./img/top1@2x.png') + ' 2x'}
              alt=""/>
            }
          </Holder>
        </section>

        <div className="tutors">
          <Holder type={1} className="tutors-holder">
            <div className="tutors-list">
              {tutorItems.slice(0, visibleItems).map((item, key) => (
                <div className="tutor-item" key={`tutor-item_${key}`}>
                  <div className="tutor-top">
                    <img
                      className="tutor-img"
                      width={'60'}
                      height={'60'}
                      src={require(`./img/tutors/${item.img}.png`)}
                      srcSet={require(`./img/tutors/${item.img}@2x.png`) + ' 2x'}
                      alt={item.name} />
                    <div className="tutor-name-block">
                      <div className="tutor-name">{item.name}</div>
                      <img width="78" height="14" src={require("./img/tutors/stars.svg")} alt=""/>
                    </div>
                    <HashLink className='link-button2 tutor-button' smooth="true" to={'#textback'}>Hire a tutor</HashLink>
                  </div>
                  <div className="tutor-middle">
                    <div className="tutor-middle-item">
                      <div className="tutor-middle-title">Sessions</div>
                      <div className="tutor-middle-value">{item.sessions}</div>
                    </div>
                    <div className="tutor-middle-item">
                      <div className="tutor-middle-title">Tutoring experience</div>
                      <div className="tutor-middle-value tutor-middle-label">{item.degree}</div>
                    </div>
                    <div className="tutor-middle-item">
                      <div className="tutor-middle-title">Degree</div>
                      <div className="tutor-middle-value tutor-middle-label">{item.tutoringExperience}</div>
                    </div>
                  </div>
                  <p className="tutor-greeting">{item.greeting}</p>
                  <div className="tutor-bottom">
                    <div className="tutor-bottom-title">Helps with:</div>
                    <div className="tutor-helps">{item.helpsWith}</div>
                  </div>
                </div>
              ))}
            </div>

            {visibleItems < tutorItems.length && (
              <button className="show-more-button" onClick={showMoreItems}>Show more tutor profiles</button>
            )}
          </Holder>
        </div>

        <Note />

        <TextBackBlock2 className={"mt20m mt60d mb60m mb80d"}/>

        {/*
        {!!isJoinModalOpen &&
        <JoinModal onClose={onAppModalClose}/>
        }
*/}

        {pageCfg.thankYouModal &&
        <ThankYouModal />
        }
      </main>

      <Footer />

    </LocalesPage>
  )
}
