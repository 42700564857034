import React from 'react';
import Slider from 'react-slick';
// import classnames from 'classnames';
import { useMobile } from '../../../../hooks';
import BubbleSpeech from '../BubbleSpeech';
import './slickSlider.scss';
import './slickSliderCustom.css';

import { feedbackItemsMain } from './feedbackItems/feedbackItemsMain';
import { feedbackItemsStudyHelp1 } from './feedbackItems/feedbackItemsStudyHelp1';
import { feedbackItemsStudyHelp2 } from './feedbackItems/feedbackItemsStudyHelp2';
import { feedbackItemsStudyHelp3 } from './feedbackItems/feedbackItemsStudyHelp3';
import { feedbackItemsStudyHelp4 } from './feedbackItems/feedbackItemsStudyHelp4';
import { feedbackItemsStudyHelp5 } from './feedbackItems/feedbackItemsStudyHelp5';
import { feedbackItemsMath } from './feedbackItems/feedbackItemsMath';
import { feedbackItemsStatistics } from './feedbackItems/feedbackItemsStatistics';
import { feedbackItemsHumanities } from './feedbackItems/feedbackItemsHumanities';
import { feedbackItemsEnglishLiterature } from './feedbackItems/feedbackItemsEnglishLiterature';
import { feedbackItemsFinanceEconomicsAccounting } from './feedbackItems/feedbackItemsFinanceEconomicsAccounting';
import { feedbackItemsNaturalSciences } from './feedbackItems/feedbackItemsNaturalSciences';
import { feedbackItemsBusiness } from './feedbackItems/feedbackItemsBusiness';
import { feedbackItemsSocialSciences } from './feedbackItems/feedbackItemsSocialSciences';

const feedbackItems = {
  feedbackItemsMain,
  feedbackItemsStudyHelp1,
  feedbackItemsStudyHelp2,
  feedbackItemsStudyHelp3,
  feedbackItemsStudyHelp4,
  feedbackItemsStudyHelp5,
  feedbackItemsMath,
  feedbackItemsStatistics,
  feedbackItemsHumanities,
  feedbackItemsEnglishLiterature,
  feedbackItemsFinanceEconomicsAccounting,
  feedbackItemsNaturalSciences,
  feedbackItemsBusiness,
  feedbackItemsSocialSciences,
};

/*
const MessageList = () => {
  return (
    <>
      {messagesList.map((item, key) => (
        <div className="feedback-item" key={`feedback-item_${key}`}>
          <div className="feedback-inner">
            {item.map((bubble, subKey) => (
              <div
                className={classnames("bubble", {"outgoing": bubble.type === "A"}, {"incoming": bubble.type === "C"})}
                key={`bubble_${subKey}`}
              >
                {bubble.message}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
*/

const SliderMobile = (feedbackItemsKey) => {
  return (
    <Slider
      //variableWidth
      //useTransform={false}
      //centerMode
      infinite={true}
      slidesToShow={1}
      slidesToScroll={1}
      className={'main-slider'}
      //adaptiveHeight={true}
      swipe={true}
      arrows={false}
      dots={true}
      customPaging={() => {
        return (
          <button className="slick-dots-btn" />
        )
      }}
    >
      {feedbackItems[feedbackItemsKey].map((item, key) => (
        <div key={`feedback-item_${key}`}>
          <div className="feedback-item">
            <img
              className="cell-header-img"
              width={'74'}
              height={'36'}
              src={require('./img/cell-header.png')}
              srcSet={require('./img/cell-header@2x.png') + ' 2x'}
              alt=""/>
            <div className="feedback-item-inner">
              {item.map((bubble, subKey) => (
                <BubbleSpeech type={bubble.type} key={`bubble_${subKey}`}>
                  {bubble.message}
                </BubbleSpeech>
              ))}
            </div>
            <img
              width={'316'}
              height={'42'}
              src={require('./img/cell-bottom.png')}
              srcSet={require('./img/cell-bottom@2x.png') + ' 2x'}
              alt=""/>
          </div>
        </div>
      ))}
    </Slider>
  )
};

const SliderDesktop = (feedbackItemsKey) => {
  return (
    <Slider
      className={'main-slider'}
      useTransform={false}
      infinite={true}
      //adaptiveHeight={true}
      //variableWidth
      //centerMode
      slidesToShow={3}
      slidesToScroll={3}
      autoplay={false}
      //autoplaySpeed={7000}
      swipe={true}
      arrows={true}
      dots={true}
      customPaging={() => {
        return (
          <button className="slick-dots-btn" />
        )
      }}
    >
      {feedbackItems[feedbackItemsKey].map((item, key) => (
        <div key={`feedback-item_${key}`}>
          <div className="feedback-item">
            <img
              className="cell-header-img"
              width={'74'}
              height={'36'}
              src={require('./img/cell-header.png')}
              srcSet={require('./img/cell-header@2x.png') + ' 2x'}
              alt=""/>
            <div className="feedback-item-inner">
              {item.map((bubble, subKey) => (
                <BubbleSpeech type={bubble.type} key={`bubble_${subKey}`}>
                  {bubble.message}
                </BubbleSpeech>
              ))}
            </div>
            <img
              width={'316'}
              height={'42'}
              src={require('./img/cell-bottom.png')}
              srcSet={require('./img/cell-bottom@2x.png') + ' 2x'}
              alt=""/>
          </div>
        </div>
      ))}
    </Slider>
  )
};

const ResponsiveSlider = ({feedbackItemsKey}) => {
  const isMobile = useMobile();
  return (
    <div className="feedback-list container">
      <div className="slider">
        {isMobile ? SliderMobile(feedbackItemsKey)
          : SliderDesktop(feedbackItemsKey)}
      </div>
    </div>
  )
};

export default ResponsiveSlider;
