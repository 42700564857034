// EnglishLiterature
export const feedbackItemsEnglishLiterature = [
  [
    {
      type: "A",
      message: "How was your last task? 😊",
    },
    {
      type: "C",
      message: "Really cool! Your expert shared some great essay tips.",
    },
    {
      type: "A",
      message: "Nice! Any tips that stood out?",
    },
    {
      type: "C",
      message: "Yes, he taught me the art of killer intros for my Literature class. Definitely a game-changing lesson!",
    },
  ],

  [
    {
      type: "C",
      message: "Had to drop a note about my previous task. Your expert? Absolute champ! He handled the English paper topic like a pro, untangling it so that even my jumbled brain could understand how to approach it and finally start writing.",
    },
  ],

  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],

  [
    {
      type: "A",
      message: "Hello there, how did you like the help you received from your assigned expert?",
    },
    {
      type: "C",
      message: "Pretty good. My expert Mikaela shared some valuable writing tips with me. Not some generic stuff, but those that actually work!",
    },
    {
      type: "C",
      message: "She taught me this clever technique for blending evidence into my arguments for literature class. I hope my papers will be at a higher level now 🔥",
    },
  ],

  [
    {
      type: "C",
      message: "Awesome! Jamie helped me grasp the essay topic!",
    },
    {
      type: "C",
      message: "He gave me guidelines on structuring and ev just clicked. English writing is no longer a nightmare! 😅",
    },
    {
      type: "C",
      message: "Can I ask for his help again pls?",
    },
  ],

  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],

  [
    {
      type: "C",
      message: "Hi, I need an expert to help me with the sources for my Literature homework.",
    },
    {
      type: "C",
      message: "Can you assign me to Mike again pls? He was like a source-seeking ninja last time, guiding me through the maze of finding material for my homework.",
    },
  ],

  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],

  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
