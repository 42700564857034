export const feedbackItemsSocialSciences = [
  [
    {
      type: "A",
      message: "Hey, Alex! How was your last task?",
    },
    {
      type: "C",
      message: "Great, Jamie is truly the best!",
    },
    {
      type: "C",
      message: "I was REALLY struggling with my Law homework but he was so patient when explaining all the concepts.",
    },
    {
      type: "A",
      message: "Glad to hear that! Thanks for your feedback 😊",
    },
  ],

  [
    {
      type: "C",
      message: "You guys rock! My expert totally helped me wrap my head around that Psychology homework. Dana talked me through it like a pro and gave real-life examples that made it click. Way less confusion now!",
    },
  ],

  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],

  [
    {
      type: "A",
      message: "Hello there, hope you enjoyed your session. How did you like the help you received from your assigned expert? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "10! In less than an hour, he managed to get me on the right track for my Sociology paper and also helped with drafting the questions for a survey. 🔥🔥 I doubted that we'd have enough time but I am very satisfied with the outcome.",
    },
  ],

  [
    {
      type: "C",
      message: "Awesome! Jamie helped me grasp the essay topic!",
    },
    {
      type: "C",
      message: "He gave me guidelines on structuring and ev just clicked. Political Science essays are no longer a nightmare! 😅",
    },
    {
      type: "C",
      message: "Can I ask for his help again pls?",
    },
  ],

  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],

  [
    {
      type: "C",
      message: "I need an expert to help me with my sociology project. It's due on Monday 🤯🤯",
    },
    {
      type: "C",
      message: "Can you assign me to Mike again pls? Love that dude!",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],

  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],

  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
