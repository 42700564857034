// /study-help-1
export const feedbackItemsStudyHelp1 = [
  [
    {
      type: "A",
      message: "How was your last task? 😊",
    },
    {
      type: "C",
      message: "Really cool! Your expert shared some great essay tips.",
    },
    {
      type: "A",
      message: "Nice! Any tips that stood out?",
    },
    {
      type: "C",
      message: "Yes, he taught me the art of killer intros. Definitely a game-changing lesson!",
    },
  ],
  [
    {
      type: "C",
      message: "My assigned expert totally helped me wrap my head around that essay topic. Dana talked me through it like a pro and gave real-life examples that made it click. Way less confusion now!",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "Hey! How did you like the help you received from your assigned expert?",
    },
    {
      type: "C",
      message: "Andy is a lifesaver! Not only did he help me choose the topic for my essay, but he also helped me understand how to find and verify the sources. I am so grateful for their support! 🔥",
    },
  ],
  [
    {
      type: "C",
      message: "Awesome! Jamie helped me organize my essay!",
    },
    {
      type: "C",
      message: "He gave me guidelines on structuring and ev just clicked. Essays are no longer a nightmare! 😅",
    },
    {
      type: "C",
      message: "Can I book a session with him for tomorrow morning?",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "Can you assign me to Colleen again, pls?",
    },
    {
      type: "C",
      message: "She was so good at guiding me in structuring arguments for my essay last time.",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
