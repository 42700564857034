import React, { useState } from 'react';

import { HashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
// import Swipe from 'react-easy-swipe';
import {CSSTransition} from "react-transition-group";
import classnames from 'classnames';

import { useMobile } from '../../../../hooks';
import Overlay from '../Modal/Overlay';
import Holder from '../../components/Holder';
import Icon from '../../components/Icons';

import './header.css';

export const Header = ({type}) => {
  const isMobile = useMobile();
  const [isMenuOpen, setMenuOpen] = useState(0);

  const onClickOpen = () => {
    setMenuOpen(true);
    document.querySelector('html').classList.add('fixed');
  };

  const onClickClose = () => {
    setMenuOpen(false);
    document.querySelector('html').classList.remove('fixed');
  };

  const onSwipeMove = (position) => {
    if (position.x <= -50) {
      onClickClose();
    }
  };

  const HeaderNavList = [
    { name: 'About Us', link: 'about-us' },
    { name: 'How It Works', link: 'how-tutoring-works' },
    { name: 'TOP Tutors', link: 'top-tutors' },
  ];

  const HeaderNav = () => {
    return (
      <nav className='header-nav-list'>
        {HeaderNavList.map((item, key) => {
          return(
            <div className='header-nav-item' onClick={onClickClose} key={`hnavmob_${key}`}>
              {/*<HashLink className='header-nav-link' smooth to={'/' + item.link}>{item.name}</HashLink>*/}
              <NavLink
                className='header-nav-link'
                activeClassName='active'
                smooth="true"
                to={'/' + item.link}
              >
                {item.name}
              </NavLink>
            </div>
          )
        })}
      </nav>
    )
  };

  return (
    <>
      <header className={classnames('header', {[`header_${type}`]: type}, {'header-fixed': isMobile && isMenuOpen})}>
        <Holder type={1} className={'header-holder'}>
          <HashLink smooth="true" to="/">
            <img
              className="header-logo-icon"
              width="120"
              height="30"
              src={require('../../assets/img/logo.svg')}
              alt=""/>
          </HashLink>
          {isMobile ?
            isMenuOpen ?
              <button className="header-nav-close-btn" onClick={onClickClose}>
                <Icon className={'header-nav-close-icon'} iconName="close-icon" />
              </button>
              :
              <button className="header-nav-open-btn" onClick={onClickOpen}>
                <img className="header-nav-open-icon" src={require('./img/ic_menu.svg')} alt=""/>
              </button>
            : <HeaderNav />
          }
        </Holder>
        {!!(isMobile && isMenuOpen) &&
          <CSSTransition
            in={isMenuOpen}
            timeout={300}
            classNames="hnav-mob-transition"
            unmountOnExit
          >
            <Holder type={1} className={'header-nav-holder'}>
              <HeaderNav />
            </Holder>
          </CSSTransition>
        }
      </header>
      {!!(isMobile && isMenuOpen) && <Overlay onClick={onClickClose} />}
    </>
  );
};
