import React, { useState } from 'react';

import Holder from '../../components/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import Note from '../../blocks/Note';
import DividingLine from '../../components/DividingLine';

import { JoinModal } from '../../blocks/Modal/JoinModal';
import { LoginModal } from '../../blocks/Modal/LoginModal';

import LocalesPage from '../LocalesPage';

import { useMobile } from '../../../../hooks';

import './become-a-tutor.css';

export default (props) => {
  const [isJoinModalOpen, setJoinModalOpen] = useState(false);
  function onJoinModalClose() {
    setJoinModalOpen(false)
  }

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  function onLoginModalClose() {
    setLoginModalOpen(false)
  }

  const isMobile = useMobile();
  //const { pageCfg } = props;

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="become-hero">
          <Holder type={1} className={'become-hero-holder'}>
            <div className="become-hero-block">
              <h1 className="become-hero-title">Excited to share<br /> knowledge at<br /> Awesome Tutors?</h1>
              <div className="become-hero-login-block">
                <h2 className="login-title">Ready to join us?</h2>
                <div className="login-button-wrapper">
                  <button className="link-button4 type2" onClick={() => setJoinModalOpen(true)}>Apply</button>
                </div>
                <div className="login-title">Already with us?</div>
                <div className="login-button-wrapper">
                  <button className="link-button4 type1" onClick={() => setLoginModalOpen(true)}>Log in</button>
                </div>
              </div>
            </div>
            {!isMobile &&
              <img
                className="desktop"
                width="804"
                height="388"
                src={require('./img/become.png')}
                srcSet={require('./img/become@2x.png') + ' 2x'}
                alt=""/>
            }
          </Holder>
        </section>

        <section className="become-info-block">
          <Holder type={1} className={'become-info-holder'}>
            <p className="info-p1">It goes without saying that a lot of graduates from top unis have a certain amount of<br className="desktop"/>{' '}
              specialized knowledge. But we are looking for enthusiastic individuals that have the ability<br className="desktop"/>{' '}
              to convey and teach this knowledge. We believe that this is a work of art that requires patience, adaptability, and persistence!</p>
            <p className="info-p2">Please apply only if you have at least 3+ years of tutoring experience!</p>
            <button className="link-button4 type2" onClick={() => setJoinModalOpen(true)}>Apply</button>
          </Holder>
        </section>

        <DividingLine type={'short1'} />

        <section className="hiring-block">
          <Holder type={1} className={'hiring-holder'}>
            <div className="hiring-title">Here’s how the hiring process is organized:</div>
            <div className="hiring-list">
              <div className="hiring-item">
                <div className="hiring-item-inner item1">
                  <div className="hiring-item-title">Send an application</div>
                  <div className="hiring-item-message">Complete a form and tell us about yourself: degree, experience, etc. Please include a short motivational letter too. Make sure you read our Honor Code carefully before applying.</div>
                </div>
              </div>
              <div className="hiring-item">
                <div className="hiring-item-inner item2">
                  <div className="hiring-item-title">Pass the examination</div>
                  <div className="hiring-item-message">After we check your application (appx. 5-7 business days) and if you are approved to proceed our manager will contact you with the details on the further 5-step examination process.</div>
                </div>
              </div>
              <div className="hiring-item">
                <div className="hiring-item-inner item3">
                  <div className="hiring-item-title">Get approved</div>
                  <div className="hiring-item-message">After you successfully pass the examination, you can start working with student requests. Just choose your own convenient hours and get ready for fruitful collaboration with students!</div>
                </div>
              </div>
              <div className="hiring-item">
                <div className="hiring-item-inner item4">
                  <div className="hiring-item-title">Get paid</div>
                  <div className="hiring-item-message">Receive the payment after every completed request based on the time you spent on sessions. The money will be securely transferred to your account and can be withdrawn once in 14 days.</div>
                </div>
              </div>
            </div>
          </Holder>
        </section>

        <Note className={'mb80'}/>

        {isJoinModalOpen &&
        <JoinModal onClose={onJoinModalClose}/>
        }

        {isLoginModalOpen &&
        <LoginModal onClose={onLoginModalClose}/>
        }


      </main>

      <Footer />

    </LocalesPage>
  )
}
