import React, { useState } from 'react';

import classnames from 'classnames';
import './index.css';
import { TextBack } from '../../components/TextBack';
import { NavLink } from 'react-router-dom';
//import { useMobile } from '../../../../hooks';

export default ({ type, className, ...props }) => {
  //const isMobile = useMobile();
  const [isBox1Checked, setIsBox1Checked] = useState(false);
  const [isBox1Error, setIsBox1Error] = useState(false);
  const [isBox2Checked, setIsBox2Checked] = useState(false);
  const [isBox2Error, setIsBox2Error] = useState(false);
  const [isBox3Checked, setIsBox3Checked] = useState(false);
  const [isBox3Error, setIsBox3Error] = useState(false);

  function handleBox1Click() {
    setIsBox1Checked(!isBox1Checked);
    setIsBox1Error(false);
  }
  function handleBox2Click() {
    setIsBox2Checked(!isBox2Checked);
    setIsBox2Error(false);
  }
  function handleBox3Click() {
    setIsBox3Checked(!isBox3Checked);
    setIsBox3Error(false);
  }
  function handleTextbackSubmit() {
    if (!isBox1Checked) {
      setIsBox1Error(true);
    }
    if (!isBox2Checked) {
      setIsBox2Error(true);
    }
    if (!isBox3Checked) {
      setIsBox3Error(true);
    }
  }

  return(
    <div className="textback textback3">
      <div className="textback__wrapper">
        <TextBack {...props}
                  isTermsNotAccepted={!isBox1Checked || !isBox2Checked || !isBox3Checked}
                  handleTextbackSubmit={() => handleTextbackSubmit()}
        />

        <div className="p__tiny textback__checkbox-block">
          <div className="textback__checkbox-list">
            <div
              className={`textback__checkbox-item ${isBox1Error ? 'error' : ''}`}
            >
              <input
                className="textback__checkbox"
                type="checkbox"
                id="terms"
                name="terms"
                checked={isBox1Checked}
                onChange={() => handleBox1Click()}
              />
              <label className="textback__checkbox-label" htmlFor="terms">
              <span className="textback__checkbox-message">
                I accept Awesome Tutors{' '}
                <NavLink
                  className="link a__underline"
                  to="/terms"
                >
                  Terms
                </NavLink>{' '}
                and{' '}
                <NavLink className="link a__underline" to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </span>
              </label>
            </div>
            <div
              className={`textback__checkbox-item ${isBox2Error ? 'error' : ''}`}
            >
              <input
                className="textback__checkbox"
                type="checkbox"
                id="updates"
                name="updates"
                checked={isBox2Checked}
                onChange={() => handleBox2Click()}
              />
              <label className="textback__checkbox-label" htmlFor="updates">
                I want to receive request updates via SMS
              </label>
            </div>
            <div
              className={`textback__checkbox-item ${isBox3Error ? 'error' : ''}`}
            >
              <input
                className="textback__checkbox"
                type="checkbox"
                id="info"
                name="info"
                checked={isBox3Checked}
                onChange={() => handleBox3Click()}
              />
              <label className="textback__checkbox-label" htmlFor="info">
                I want to receive notifications about Awesome Tutors products and services via SMS
              </label>
            </div>
          </div>
        </div>

        <div className="agree">
          SMS messages are sent by Awesome Tutors. Message and data rates may apply.<br className="desktop"/>
          Reply HELP for help and STOP to opt-out.
        </div>
      </div>
    </div>
  )
};
