import React from 'react';
import {NavLink} from 'react-router-dom'
import ContentPage from '../';
//import LocalesPage from './../LocalesPage';

export default () => (
    <ContentPage>
      <h1 className="content-title">The Honor Code</h1>

      <div className="content-p"><h2 className="content-inline-headline">The Honor Code is a guideline designed to promote the responsible use of the Awesome Tutors platform.</h2> It outlines our demands on students and educators for setting and maintaining the highest standards in both academic and professional practice.<br/>
        The Awesome Tutors platform does not support academic dishonesty, and will not take part in encouraging students to lie, cheat, or get grades or degrees they have not earned</div>

      <div className="content-h2">Including but not limited to:</div>
      <ul className="content-ul">
        <li>Cheating: both asking for and offering unauthorised third-party help with homework;</li>
        <li>Impersonation: both asking for and pretending to be a student for whatever reason;</li>
        <li>Plagiarism: both asking for and using another person's work without giving them due credit.</li>
      </ul>

      <p className="content-p">Tutors are not allowed to take tests or complete graded tasks for students. For any reason, tutors are not allowed to falsify data or fabricate research findings or reports. Tutors are not allowed to deliberately assist students in cheating, fabricating documents, committing fraud, plagiarizing, or engaging in any other behavior that contravenes college policies. Any proof that a tutor purposefully assisted fraud will result in their immediate removal from the platform.</p>

      <p className="content-p">The Awesome Tutors platform does not support and will not participate in any corporate dishonesty or policy violations, including knowing assisting Users to commit fraud or contribute to any kind of illegal activity.</p>

      <p className="content-p">If there is any proof that a tutor has purposefully assisted any illegal activity, they will be immediately terminated from the platform.</p>

      <div className="content-h2">For Students</div>
      <p className="content-p">The Awesome Tutors platform is  aware of the demands and time restraints that come with studying. However, the incentive is not worth the risk students take by breaking the academic integrity rules at their institution. The Awesome Tutors platform does not permit completion without comprehension, copying solutions or seeking final answers. Students are not permitted to engage in any unlawful conduct or violate any college policy while using The Awesome Tutors platform.</p>

      <div className="content-h2">For Tutors</div>
      <p className="content-p">We at the Awesome Tutors platform give you a chance and have faith in your ability to use our platform to showcase yourself as an expert to students. As a tutor working at the Awesome Tutors platform, you must adhere to the following rules:</p>

      <ul className="content-ul">
        <li>Tutors  are not permitted to initiate or support situations that would contravene corporate or academic policies.</li>
        <li>Tutors are not permitted to contribute to the aforementioned conditions.</li>
        <li>Tutors are not permitted to assist students in cheating or fraud.</li>
        <li>Tutors are not permitted to requesting students’ personal contact information</li>
      </ul>

      <div className="content-h2" id="list">List Of Prohibited Tasks</div>

      <p className="content-p">Any User who violates the limitations outlined in our Honor Code will be suspended and their account will be permanently deleted with no chance of restoring. The following is a list of requests that are prohibited by  the Awesome Tutors platform (please note that this list is not all-inclusive and should only be used as a general guide):</p>

      <ul className="content-ul">
        <li>Creating false reports, fabricating work experience for resumes,</li>
        <li>Completing assignments for school or college; pretending to be a student for whatever reason;</li>
        <li>Completing projects for students to show as their own work;</li>
        <li>Obtaining answers to tests, exams, and labs against a professor's or institution's directive to not use outside assistance;</li>
        <li>Falsifying any kind of document;</li>
        <li>Dissertation, thesis, and term paper ghostwriting;</li>
        <li>Fabricating facts, information, or references;</li>
      </ul>

      <p className="content-p">as well as completing other requests that go against any institution policies.</p>

      <div className="content-h2">Honor Code Abuse</div>

      <p className="content-p">If you become aware of any case of Honor Code abuse, please, immediately contact us to report it.</p>

      <NavLink className="link-button3" to={'/report-abuse'}>Report abuse</NavLink>

  </ContentPage>
)
