import React from 'react';

import classnames from 'classnames';
import './index.css';
import Holder from '../../components/Holder';
import DemoSubjects from '../../components/DemoSubjects';

export default ({ type }) => (
  <section className={classnames('top-section', {[`top-section_${type}`]:type} )}>
    <Holder type={1} className='top-holder'>
      <div className="top-title">What defines an awesome tutor for us?</div>
      <div className="top-message">We are super selective when partnering with new tutors. That’s why we look for a 3-year experience minimum in each area,<br className="desktop"/> backed by education from well-known institutions like those below.</div>

      <DemoSubjects oneRow={true} />
    </Holder>
  </section>
);

/*
export const TopExpert2  = ({ type }) => (
  <section className={classnames('top-section', {[`top-section_${type}`]:type} )}>
    <Holder type={1} className='top-holder'>
      <div className="top-title">Our tutors are the TOP experts<br className="desktop" />{' '}
        in their academic fields</div>
      <div className="top-message">We only hire vetted professionals with 3+ years of experience in tutoring. We assure you that our<br
        className="desktop"/> managers will find you a perfect match with a relevant degree for any task!</div>

      <DemoSubjects oneRow={true} />
    </Holder>
  </section>
);
*/
