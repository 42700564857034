import React, { Component } from 'react';
import ContentPage from '../';
import classnames from 'classnames';
import Attach from './Attach';
import { Form1 } from './Form';
import InputError from './Form/InputError';
import regexps from './../../../../../utils/regexps';
import { ReportAbuseModal } from '../../../blocks/Modal/ReportAbuseModal'

const reportAbuseItems = [
  {
    title: 'Breaching academic integrity',
    description: 'We are strongly against both using or offering third-party homework assistance and impersonating a student for exams or tests.',
  },
  {
    title: 'Plagiarism',
    description: 'We are strongly against using another person\'s work without giving them due credit.',
  },
  {
    title: 'Nudity',
    description: 'We are strongly against sharing or publishing of content depicting nudity or other sexually explicit material by tutors.',
  },
  {
    title: 'Illegal or unethical activities',
    description: 'We are strongly against tutors being engaged in activities that violate the law or academic policies. We have a zero tolerance policy for facilitating drug use, weapons and explosives sale or resale, bidding or gambling.',
  },
  {
    title: 'Hatred or violence',
    description: 'We are strongly against tutors sharing content that promotes hatred, violence, or threatens and harasses others.',
  },
  {
    title: 'Private and confidential information',
    description: 'We are strongly against tutors asking for your confidential information: passwords, credit card details, bank account numbers or similar types of private information.',
  },
  {
    title: 'Other',
    description: 'If you want to report a case that doesn\'t fall under the aforementioned conditions, please select this option.',
  },
];

const ReportAbuseItem = ({ title, description, index, onChange, activeItem, ...rest }) => {
  return (
    <div className="reportAbuse-radio">
      <input
        id={`reportAbuse-${index}`}
        className="reprotAbuse-radio"
        type="radio"
        name="radio"
        value={title}
        onChange={onChange}
        checked={reportAbuseItems[index]['title'] === activeItem}
      />
      <label
        className="reportAbuse-label"
        htmlFor={`reportAbuse-${index}`}
      >
        { title }
        <p className="p p__small p__darker">{ description }</p>
      </label>
    </div>
  )
};


class ReportAbuse extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      radio: '',
      email: '',
      orderNumber: '',
      comment: '',
      errors: {},
      isHasClick: false,
      isDisabled: false,
      isSuccess: false,
      isClear: false,
    };

    this.state = this.initialState;

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(e) {
    this.setState({ isHasClick: true });

    const isError = this.validate();
    if (!isError) {
      this.setState({ isSuccess: true });
    }
  }

  onClose() {
    this.setState({
      isClear: true,
    }, () => {
      this.setState({...this.initialState});
    });
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    const { isHasClick } = this.state;

    this.setState({
      [name]: value
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    });
  }

  validate() {
    const {
      email,
      orderNumber,
      radio,
    } = this.state;

    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
      orderNumber: !orderNumber.trim().length ? 'Required field' : null,
      //comment: !comment.trim().length ? 'Required field' : null,
      radio: !radio.trim().length ? 'Select the abuse type' : null,
    };

    const isError = Object.keys(errors).some(item => errors[item]);

    this.setState({
      errors,
      isDisabled: isError,
    });

    return isError;
  }

  render() {
    const {
      radio,
      email,
      orderNumber,
      comment,
      errors,
      isDisabled,
      isSuccess,
      isClear,
    } = this.state;

    return (
      <ContentPage className={'report-abuse'}>
        <h1 className="content-title">Report Abuse</h1>

        <p className="content-p">At Awesome Tutors, we set the highest standards of tutoring services. If you believe that the tutor assigned to you agreed to provide unethical service, please, report this case for abuse.</p>

        <div className="content-h2 mt40">Select the abuse case you want to report:</div>

        <Form1 className="form">
          <fieldset className="fieldset">
            {reportAbuseItems.map((item, index) => {
              return (
                <ReportAbuseItem
                  key={`ReportAbuseItem-${index}`}
                  index={index}
                  activeItem={radio}
                  {...item}
                  onChange={this.onChangeInput}
                />
              );
            })}
            {!!errors.radio &&
            <InputError>{ errors.radio }</InputError>
            }
          </fieldset>

          <fieldset className="fieldset two-in-one mt40d">
            <div className="fieldset-item">
              <div className="input-wrapper">
                <input
                  className={classnames("input", {"input__error": errors.email })}
                  type="email"
                  name="email"
                  value={email}
                  placeholder="*Enter your email"
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="email"
                  onChange={this.onChangeInput}
                />
                {!!errors.email &&
                <InputError>{ errors.email }</InputError>
                }
              </div>
            </div>
            <div className="fieldset-item">
              <div className="input-wrapper">
                <input
                  className={classnames("input", {"input__error": errors.orderNumber })}
                  type="text"
                  name="orderNumber"
                  value={orderNumber}
                  placeholder="*Enter your request"
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="off"
                  onChange={this.onChangeInput}
                />
                {!!errors.orderNumber &&
                <InputError>{ errors.orderNumber }</InputError>
                }
              </div>
            </div>
          </fieldset>

          <fieldset className="fieldset">
            <div className="input-wrapper">
            <textarea
              className={classnames("textarea", {"textarea__error": errors.comment })}
              name="comment"
              value={comment}
              placeholder="Share additional information with us"
              onChange={this.onChangeInput}
            />
              {!!errors.comment &&
              <InputError>{ errors.comment }</InputError>
              }
            </div>
          </fieldset>

          <fieldset className="fieldset two-in-one2">
            <div className="fieldset-item">
              <Attach id="report-abuse-file" clear={isClear}>Attach file</Attach>
            </div>
            <div className="fieldset-item">
              <button type="button" className="link-button4 type2" onClick={this.onSubmit} disabled={isDisabled}>Send report</button>
            </div>
          </fieldset>

          <fieldset className="fieldset">
            <p className="note">Please note that we won't let the tutor know who reported them. Moreover, we will immediately suspend their account, if they do not not comply with the above-mentioned policies, terms and conditions or privacy policy of our service.</p>
          </fieldset>
        </Form1>

        {isSuccess &&
          <ReportAbuseModal isOpen={isSuccess} onClose={this.onClose} />
        }

      </ContentPage>
    )
  }
}

export default ReportAbuse;
