import React from 'react';
import Icon from './../../components/Icons';
import classnames from 'classnames';

import Accordion, {
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon as AccordionIconBase,
  // AccordionItemHolder
} from '../../../../base_components/Accordion';

import './index.css';
import Holder from '../../components/Holder';

import { faqItemsMain } from './faqItems/faqItemsMain';
import { faqItemsStudyHelp1 } from './faqItems/faqItemsStudyHelp1';
import { faqItemsStudyHelp2 } from './faqItems/faqItemsStudyHelp2';
import { faqItemsStudyHelp3 } from './faqItems/faqItemsStudyHelp3';
import { faqItemsStudyHelp4 } from './faqItems/faqItemsStudyHelp4';
import { faqItemsStudyHelp5 } from './faqItems/faqItemsStudyHelp5';
import { faqItemsMath } from './faqItems/faqItemsMath';
import { faqItemsStatistics } from './faqItems/faqItemsStatistics';
import { faqItemsHumanities } from './faqItems/faqItemsHumanities';
import { faqItemsEnglishLiterature } from './faqItems/faqItemsEnglishLiterature';
import { faqItemsFinanceEconomicsAccounting } from './faqItems/faqItemsFinanceEconomicsAccounting';
import { faqItemsNaturalSciences } from './faqItems/faqItemsNaturalSciences';
import { faqItemsBusiness } from './faqItems/faqItemsBusiness';
import { faqItemsSocialSciences } from './faqItems/faqItemsSocialSciences';

const faqItems = {
  faqItemsMain,
  faqItemsStudyHelp1,
  faqItemsStudyHelp2,
  faqItemsStudyHelp3,
  faqItemsStudyHelp4,
  faqItemsStudyHelp5,
  faqItemsMath,
  faqItemsStatistics,
  faqItemsHumanities,
  faqItemsEnglishLiterature,
  faqItemsFinanceEconomicsAccounting,
  faqItemsNaturalSciences,
  faqItemsBusiness,
  faqItemsSocialSciences,
};

export const FAQ = ({className, faqItemsKey}) => {
  return (
    <div className={classnames('faq', className)}>
      <Holder>
        <div className='faq-title'>FAQ</div>
        <Accordion>
          {faqItems[faqItemsKey].map((item, index) => {
            return (
              <AccordionItem
                index={index}
                tracking={typeof item.Q === 'object' ? React.Children.toArray(item.Q.props.children).map(child => (typeof child === 'string' ? child : '')).join('') : item.Q} // if the title is an object, then convert it to text by removing the html tags
                key={`faqItem${index}`}
              >
                <AccordionHead>
                  <div className='faq-question'>{item.Q}</div>
                  <AccordionIcon icons={['ic_plus', 'ic_minus']} />
                </AccordionHead>
                <AccordionBody>
                  <p className='faq-answer'>{item.A}</p>
                </AccordionBody>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Holder>
    </div>
  )
};

export const AccordionIcon = ({ icons, ...rest }) => <AccordionIconBase {...rest}>
  {icons.map((item, index) => {
    return (
      <Icon
        className={classnames('svg-icon', index ? "minus-icon" : "plus-icon")}
        iconName={`${item}`}
        key={`accordion-icon-${item}`}
      />
    )
  })}
</AccordionIconBase>;
