import React, { Component } from 'react';
import { setCookie, checkCookie } from '../../../../utils';
import { getUserIpInfo } from '../../../../api';
import { NavLink } from 'react-router-dom';
import './cookie-popup.css';

import { REACT_APP_SITENAME } from './../../../../config';
import Holder from '../../components/Holder';

class CookiePopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCookieSet: false,
      isValidCountry: false,
    };

    this.handleOnClickSetCookie = this.handleOnClickSetCookie.bind(this);
  }

  componentDidMount() {
    const isCookieSet = checkCookie('__cookieEnabled');

    if (!isCookieSet) {
      getUserIpInfo()
        .then((resp) => {
          const code = resp?.country_code;

          if (!code) {
            return false;
          }

          this.setState({
            isValidCountry: ['us', 'ca'].indexOf(code.toLowerCase()) === -1,
          });
        })
        .catch((error) => error);
    }

    this.setState({
      isCookieSet: isCookieSet,
    });
  }

  handleOnClickSetCookie() {
    // console.log('set cookie');
    this.setState(
      {
        isCookieSet: true,
      },
      () => {
        setCookie('__cookieEnabled', 1, 365);
      }
    );
  }

  render() {
    const { isFetching } = this.props;

    if (isFetching) {
      return null;
    }

    const { isValidCountry, isCookieSet } = this.state;
    const hasCookiePopUp = isValidCountry && !isCookieSet;
    //console.log(1, isValidCountry)

    return (
      hasCookiePopUp && (
        <div className="cookie-popup">
          <Holder type={1} className={'cookie-popup-holder'}>
            <div className="cookie-banner">
              <img
                className="cookie-img"
                width="60"
                height="60"
                src={require('./img/cookie.png')}
                srcSet={require('./img/cookie@2x.png') + ' 2x'}
                alt=""/>
              <div className="cookie-message">
                Awesome-tutors.com uses cookies so you get the best experience on our website.{' '}
                <NavLink className="cookie-link" to="/cookies">
                  Learn more
                </NavLink>
              </div>
              <button
                className="link-button3 cookie-button"
                onClick={this.handleOnClickSetCookie}
              >
                Got it!
              </button>
            </div>
          </Holder>
        </div>
      )
    );
  }
}

export default CookiePopUp;
