import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import Holder from '../../components/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import { Manager } from '../../blocks/Manager';

import LocalesPage from '../LocalesPage';

//import { useMobile } from '../../../../hooks';
//import LocalesPage from '../LocalesPage';

import { updateUser, checkLeadExists } from '../../../../redux/actions';
import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';
import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

import './thank-you.css';
import { trackValues } from '../../../../lrtracker';
import { TextBack } from '../../components/TextBack';

export default (props) => {
  //const isMobile = useMobile();
  //const { pageCfg } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const urlParams  = parseQuery(location.search);
  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';
  //const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url) || decodeURIComponent(_.get(urlParams, 'source_url', '')) || '';

  let source_url = '';
  try {
    source_url = decodeURIComponent(props.location.state?.source_url ?? '');
  } catch (error) {
    console.error('Error decoding source_url from state:', error);
  }

  if (!source_url) {
    try {
      source_url = decodeURIComponent(_.get(urlParams, 'source_url', ''));
    } catch (error) {
      console.error('Error decoding source_url from URL params:', error);
    }
  }

  source_url = source_url || '';

  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Please enter a valid email address' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    let phone;
    if (typeof window !== 'undefined') {
      phone = _.get(parseQuery(window.location.search), 'to', '');
    }

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = (event) => {
    if (event) event.preventDefault();
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="thank-you-hero">
          <Holder type={1} className={'thank-you-hero-holder'}>
            <div className="thank-you-hero-block">
              <img
                width="33" height="32"
                src={require('./img/thumb.svg')}
                alt=""/>

              <h1 className="thank-you-title">
              {landing_type === 'second' || landing_type === 'undelivered' ?
                (
                  <>Done! We've just messaged you again.</>
                )
                : (
                  <>Thanks for contacting us, we'll text you back in a few sec!</>
                )
              }
              </h1>

              <h2 className="thank-you-subtitle">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>If you still have not received our SMS, check if you entered a correct phone number<br
                      className="desktop"/> or please let us know about this issue at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="link">{REACT_APP_CONTACT_EMAIL}</a></>
                  )
                  : (
                    <>If you don't hear from us soon, click the button below and we'll text you again.</>
                  )
                }
              </h2>

              <div className="textback">
                <div className="textback__wrapper">
                  <TextBack {...props} submitBtnTxt={landing_type === 'second' || landing_type === 'undelivered' ? 'Text me now' : 'Get help asap'} />
                </div>
              </div>

            </div>
          </Holder>
        </section>

        <section className="thank-you-email-block">
          <Holder type={1} className={'thank-you-email-holder'}>

          {!isSubmitted ? (
            <>
              <div className="thank-you-email-title">
                Enter your email if you want to<br className="mobile"/>{' '}
                receive discounts<br className="desktop"/> and promos from<br className="mobile"/>{' '}
                Awesome Tutors
              </div>
              <form className="email-form">
                <div className="input-wrapper">
                  <input
                    className={classnames('form-input', {
                      input__error: isErrors,
                    })}
                    required
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="email"
                    onChange={onChange}
                  />
                  {isErrors && (
                    <div className="form-item-error">{errors.email || errors.server}</div>
                  )}
                </div>
                <button
                  className={classnames('submit-button link-button4 type1', {
                    submitted: isSubmitted || isPatching,
                  })}
                  disabled={isSubmitted || isPatching}
                  onClick={(e) => onSubmit(e)}
                >
                  Send
{/*
                  {isPatching ? (
                    <ul className="loading">
                      <li />
                      <li />
                      <li />
                      <li />
                    </ul>
                  ) : (
                    <>
                      {isSubmitted ? 'Email submited' : 'Send'}
                    </>
                  )}
*/}
                </button>
              </form>
            </>
          ) : (
            <>
            <div className="thank-you-sharing-title">Thank you for sharing<br className="mobile"/> your email!</div>
            <div className="thank-you-sharing-subtitle">We'll let you know about all our<br/> discounts and promos.</div>
            </>
          )}
          </Holder>
        </section>

        <Manager type="thank-you" className={'mt60'} />

      </main>

      <Footer />

    </LocalesPage>
  )
}
