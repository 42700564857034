import React from 'react';
import { NavLink } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import classnames from 'classnames/index';
import { useMobile } from '../../../../hooks';
// import Icons from '../Icons';

import {
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_ADDRESS_US
} from './../../../../config';

import Holder from '../../components/Holder'
import './footer.css';

export const Footer = ({type}) => {
  const isMobile = useMobile();

  const FooterNavList = [
    { name: 'About Us', link: '/about-us' },
    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: 'How It Works', link: '/how-tutoring-works' },
    { name: 'Refund Policy', link: '/refund-policy' },
    { name: 'TOP Tutors', link: '/top-tutors' },
    { name: 'Cookie Policy', link: '/cookies' },
    { name: 'Become a Tutor', link: '/become-a-tutor' },
    { name: 'Honor Code', link: '/honor-code' },
    { name: 'Terms & Conditions', link: '/terms' },
    { name: 'Report Abuse', link: '/report-abuse' },
  ];

  const FooterNav = () => {
    return (
      <nav className='footer-nav-list'>
        {FooterNavList.map((item, key) => {
          return(
            <div className='footer-nav-item' key={`footer_item_${key}`}>
              <NavLink className='footer-nav-link' to={item.link}>{item.name}</NavLink>
            </div>
          )
        })}
      </nav>
    )
  };

  return (
    <footer className={classnames('footer', {[`footer_${type}`]:type})}>
      <Holder type={1} className={'footer-holder'}>
        <img className="footer-logo-icon"
             width="95.911"
             height="24"
             src={require('../../assets/img/logo.svg')}
             alt=''/>
        <div className="footer-block">
          <div className="footer-inner-block">
            <FooterNav />
            {isMobile ? <img className="footer-payment-img mobile" width="193.5" height="149" src={require('./img/payment-mobile.png')} alt=""/>
              : <img className="footer-payment-img desktop" width="379" height="102" src={require('./img/payment-desktop.png')} alt=""/> }
          </div>
          <div className="footer-contact-block">
            <div className="footer-contact-address">Our Address: {REACT_APP_ADDRESS_US}</div>
            {!isMobile && <>&nbsp;&nbsp;•&nbsp;&nbsp;</>}
            <div className="footer-contact-email">Contact Us:{' '}
              <a className="footer-contact-link" href={"mailto:" + REACT_APP_CONTACT_EMAIL}>
              {REACT_APP_CONTACT_EMAIL}
              </a>
            </div>
            <div className="footer-copyright">&copy; 2023 awesome-tutors.com</div>
          </div>
        </div>
      </Holder>
    </footer>
  )
};

