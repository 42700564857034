import React from "react";
import { HashLink } from 'react-router-hash-link';
// import { Link } from 'react-router-dom'

export const faqItemsMain = [
  {
    Q: "How do you select tutors?",
    A: <>Our customer care managers select the best-matching tutor available to help you with your request. When assigning a tutor they take into consideration their major, experience, rating, and various additional parameters to make sure the tutor can perfectly meet your requirements.</>
  },
  {
    Q: <>What requests can a tutor<br className={"mobile"} /> help me with?</>,
    A: <>Our tutors are all skilled to help you with any subject unless your request is illegal or violates any college policies. Please read our <HashLink className="link" to="/honor-code">Honor Code</HashLink> and the <HashLink className="link" to="/honor-code#list">list of prohibited requests</HashLink> for details.</>
  },
  {
    Q: <>How quickly can I get help<br className={"mobile"} /> from a tutor?</>,
    A: <>We provide help 24/7 including the weekends and will quickly respond to your inquiry at any time of the day. However, we strongly advise you to send a help request at least 3 hours before the due time you want to get assistance from a tutor. This is just to make sure the tutor has enough time to get prepared for the session.</>
  },
  {
    Q: <>How can I be sure that my<br className={"mobile"} /> money is safe?</>,
    A: <>You can submit the prepayment with any Credit/Debit card by following the invoice link we send you after confirming your request. The link automatically takes you to a 128-bit encrypted HTTPS payment page. We have no access to your CC data or your private info. The money is released to the tutor only after your tutoring session was successfully completed.</>
  },
];
