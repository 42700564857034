import React from 'react';

//main landing: https://awesome-tutors.com/, https://awesome-tutors.com/1/
export const hiwContentMain = {
  hiwTitle: 'Here is how it works',
  hiwItems: [
    {
      number: 1,
      title: 'Send your request',
      message: 'Text us the topic or task you have, or send a pic. Please include as many details as possible!',
      slide: [
        {
          type: 'A',
          message: <>Hey, I need help with understanding these equations for my Math 201 course <span role="img" aria-label="">😭</span></>,
        },
        {
          type: 'img',
          file: 'screen@2x.png',
          width: 107,
          height: 107,
        }
      ],
    },
    {
      number: 2,
      title: 'Get your match',
      message: 'Your care manager will match you with the best-fit expert based on your needs.',
      slide: [
        {
          type: 'C',
          message: 'Hello there, got your pic!',
        },
        {
          type: 'C',
          message: "I see that tutor Ashley with a Master's in Math is available now. You worked with her before on your other math course.",
        },
      ]
    },
    {
      number: 3,
      title: 'Nail any subject',
      message: 'Work one-on-one with the assigned tutor, learn easily, and enjoy the well-deserved results.',
      slide: [
        {
          type: 'A',
          message: 'Yay, thanks! I remember Ashley, she is great!',
        },
        {
          type: 'A',
          message: <>Please assign her to me and I'm ready to get started <span role="img" aria-label="">🙏</span></>,
        },
      ]
    },
  ]

};
