import React from 'react';

import Holder from '../../components/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import DividingLine from '../../components/DividingLine';
import TopExperts from '../../blocks/TopExpertsUniversity/TopExperts';
import TextBackBlock from '../../blocks/TextBackBlock';
import Feedback from '../../blocks/Feedback';
import Note from '../../blocks/Note';
import { Join } from '../../blocks/Join';

import { ThankYouModal } from '../../blocks/Modal/ThankYouModal';

import LocalesPage from '../LocalesPage';

import { useMobile } from '../../../../hooks';

import './hiw.css';
//import classnames from 'classnames/index';

export default (props) => {
  const isMobile = useMobile();
  const { pageCfg } = props;
/*
  const [isJoinModalOpen, setJoinModalOpen] = useState(false);

  function onAppModalClose() {
    setJoinModalOpen(false)
  }
*/

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="hiw-hero">
          <Holder type={1} className={'hiw-hero-holder'}>
            <div className="hiw-hero-column">
              <div className="hiw-hero-block">
                <h1 className="hiw-hero-title">How tutoring works at Awesome Tutors</h1>
                <h2 className="hiw-hero-subtitle">It's even easier than you think, dig in!</h2>
              </div>
              {isMobile ?
                <img
                  className="mobile"
                  width="100%" //358
                  height="auto" //235
                  src={require('./img/hiw1.png')}
                  srcSet={require('./img/hiw1@2x.png') + ' 2x'}
                  alt=""/>
                :
                <img
                  className="desktop"
                  width="389"
                  height="186"
                  src={require('./img/hiw2.png')}
                  srcSet={require('./img/hiw2@2x.png') + ' 2x'}
                  alt=""/>
              }
            </div>
            {!isMobile &&
              <img
                className="desktop"
                width="808"
                height="388"
                src={require('./img/hiw3.png')}
                srcSet={require('./img/hiw3@2x.png') + ' 2x'}
                alt=""/>
            }
          </Holder>
        </section>

        <section className="hiw-steps">
          <Holder type={2} className={'hiw-steps-holder'}>
            <div className="hiw-steps-list">
              <div className="hiw-steps-item">
                <div className="hiw-item-number">1</div>
                <div>
                  <div className="hiw-item-title">You text us your requirements to the tutoring session</div>
                  <div className="hiw-item-message">Describe your request or send over files, pics, docs — anything you have at hand. The more details the better. Yes, just via text!</div>
                </div>
              </div>
              <div className="hiw-steps-item">
                <div className="hiw-item-number">2</div>
                <div>
                  <div className="hiw-item-title">The caring manager
                    finds you a tutor</div>
                  <div className="hiw-item-message">The manager who got in touch with you assigns a tutor after a detailed check of request details (takes appx. 15 min).</div>
                </div>
              </div>
              <div className="hiw-steps-item">
                <div className="hiw-item-number">3</div>
                <div>
                  <div className="hiw-item-title">Have an amazing one-on-one experience</div>
                  <div className="hiw-item-message">Send a prepayment and gain study skills to conquer any subject, find answers, solve problems, and work independently!</div>
                </div>
              </div>
            </div>

            <div className="hiw-label">Turn your study struggles into academic success!</div>

          </Holder>
        </section>

        <DividingLine />
        <TextBackBlock />
        <DividingLine />

        <TopExperts />

        <Feedback type={2} feedbackItemsKey = {pageCfg.feedbackItemsKey} />

        <Note type={2} />

        <Holder type={1}>
          <DividingLine type={'short2'} />
        </Holder>

        <Join />
        {/*
        {!!isJoinModalOpen &&
        <JoinModal onClose={onAppModalClose}/>
        }
*/}

        {pageCfg.thankYouModal &&
        <ThankYouModal />
        }
      </main>

      <Footer />

    </LocalesPage>
  )
}
