// https://awesome-tutors.com/, /1, /how-tutoring-works
export const feedbackItemsMain = [
  [
    {
      type: "A",
      message: "Hey, Alex! How was your tutoring session?",
    },
    {
      type: "C",
      message: "Great, Jamie is truly the best!",
    },
    {
      type: "C",
      message: "I was REALLY struggling with my Econ class but he was so patient when explaining all the concepts.",
    },
    {
      type: "A",
      message: "Glad to hear that! Thanks for your feedback 😊",
    },
  ],
  [
    {
      type: "C",
      message: "You guys rock! You’ve helped more than I can ever hope for. You know, Math has never been my strongest subject to the point that I barely got through high school :((((  But thanks to Sylvia I started believing in myself!!",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest tutor ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "Hello there, hope you enjoyed your session. How did you like the help you received from your tutor? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "10! In less than an hour, he managed to get me on the right track for my Sociology research and also helped with drafting the questions for a survey. 🔥🔥  I doubted that we'd have enough time but I am very satisfied with the outcome.",
    },
  ],
  [
    {
      type: "C",
      message: "Hello, Jasmine, I just wanted to say thank you for the good choice of a tutor!",
    },
    {
      type: "C",
      message: "But I think I need at least 1 more session. I had a class an hour ago and now I have a new topic I'm struggling with 😟",
    },
    {
      type: "C",
      message: "Can I book a session with Dylan for tomorrow morning?",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: " I need a tutor to help me with my English project. It's due on Monday 🤯🤯",
    },
    {
      type: "C",
      message: "Can you book a session with Mike asap pls? Of all the tutors I had, Mike's sessions are spot on, love that dude!",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another tutoring service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
