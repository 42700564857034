// /study-help-5
export const feedbackItemsStudyHelp5 = [
  [
    {
      type: "C",
      message: "Hey, your expert Jake did a great job! Helped me understand the requirements to my discussion post.",
    },
    {
      type: "A",
      message: "Happy to hear that. If you ever need assistance with other discussion posts or any homework tasks, you know where to find Jake! 😊",
    },
  ],
  [
    {
      type: "C",
      message: "I just wanted to drop a quick note about my last request – it was a total win. Mike were a champ in answering my questions. Seriously, no question was too small or too big for him. It's like he had all the answers in his back pocket. 🔥",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "How was your last task?",
    },
    {
      type: "C",
      message: "Pretty good. My expert Mikaela shared some valuable writing tips with me. Not some generic stuff, but those that actually work!",
    },
    {
      type: "C",
      message: "She taught me this clever technique for blending evidence into my arguments. I hope my projects will be at a higher level now 🔥",
    },
  ],
  [
    {
      type: "A",
      message: "Hello there, how did you like the help you received from your expert? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "5, ofc. Nick became a guiding light for the project I am working on. I felt so confused, but he just navigated me like a pro. Big thanks!",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "Can you assign me to Colleen pls?",
    },
    {
      type: "C",
      message: "Last week she helped me choose research methods for my project. Loved her!",
    },
    {
      type: "C",
      message: "I need just her advice again 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
