const services = [
  {
    name: 'Instant reply via SMS',
    AT: true,
    other: false,
  },
  {
    name: 'Caring support 24/7',
    AT: true,
    other: false,
  },
  {
    name: 'Best expert selection for your needs',
    AT: true,
    other: false,
  },
  {
    name: 'Free SMS updates',
    AT: true,
    other: false,
  },
  {
    name: 'All college subjects covered',
    AT: true,
    other: true,
  },
  {
    name: 'Money back guarantee',
    AT: true,
    other: true,
  },
];

export { services };
