import React from 'react';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import Holder from '../../components/Holder';
import DividingLine from '../../components/DividingLine';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import { TextBack } from '../../components/TextBack';
import { HowItWorks } from '../../blocks/HowItWorks';
import Feedback from '../../blocks/Feedback';
import TopExpertsUniversity from '../../blocks/TopExpertsUniversity/TopExpertsUniversity';
import Note from '../../blocks/Note';
import TextBackBlock from '../../blocks/TextBackBlock';
import TextBackBlock2 from '../../blocks/TextBackBlock2';
import TextBackBlock3 from '../../blocks/TextBackBlock3';
import { ThankYouModal } from '../../blocks/Modal/ThankYouModal';
import Icon from '../../components/Icons';

import { FAQ } from '../../blocks/FAQ';

import LocalesPage from '../LocalesPage';

import { useMobile } from '../../../../hooks';
//import LocalesPage from '../LocalesPage';

import { services } from './services';
import './landing.css';
//import classnames from 'classnames';

export default (props) => {
  const isMobile = useMobile();
  const { pageCfg } = props;
/*
  const [isJoinModalOpen, setJoinModalOpen] = useState(false);

  function onAppModalClose() {
    setJoinModalOpen(false)
  }
*/

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="landing-hero" id="hero">
          <Holder type={1} className={'landing-hero-holder'}>
            <div className="landing-hero-block">
              <div className="landing-hero-column">
                <h1 className="landing-title">{ pageCfg.title || 'Get urgent help with any tasks'}</h1>
                {pageCfg.secondTitle ?
                  <>
                    <h2 className="landing-second-title">{pageCfg.secondTitle}</h2>
                    <h3 className="landing-subtitle">
                      { pageCfg.subtitle || ' We’ll text you in just 15 seconds! 😊'}
                    </h3>
                  </>
                  :
                  <h2 className="landing-subtitle">
                    { pageCfg.subtitle || ' We’ll text you in just 15 seconds! 😊'}
                  </h2>
                }

                {pageCfg.textbackCheckbox ?
                  <TextBackBlock3/>
                  :
                  <div className="textback">
                    <div className="textback__wrapper">
                      <TextBack {...props} />
                      <div className="agree">
                        No spam. By clicking the button, you agree to{' '}
                        <br className="mobile"/>
                        <NavLink className="link" to="/terms">Terms</NavLink>
                        {' '}&{' '}
                        <NavLink className="link" to="/privacy-policy">Privacy Policy</NavLink>.
                      </div>
                    </div>
                  </div>
                }

              </div>
              {!isMobile &&
              <img
                className="landing-hero-img"
                src={require('./img/hero/hero-image.png')}
                srcSet={require('./img/hero/hero-image@2x.png') + ' 2x'}
                alt=""/>
              }
            </div>
          </Holder>
        </section>

        {!pageCfg.hideManagerSection &&
          <>
            <section className="manager-section">
              <Holder type={1} className='manager-holder'>
                <div className="manager-wrapper">
                  <div className="manager-title">Awesome Tutors is an on-demand tutoring platform</div>
                  <p className="manager-message">We’ve curated a network of the best educators so you don’t have to. Send us your request and get an ideal tutor handpicked<br className="desktop"/> for your specific topic or task.</p>
                  <Link to="/about-us" className="link-button">
                    <span className="message">Learn more</span>
                    <Icon
                      className={'svg-icon'}
                      iconName={'ic_arrow-right'}
                    />
                  </Link>
                </div>
              </Holder>
            </section>

            <DividingLine className="desktop" />
          </>
        }

        <HowItWorks hiwContentKey={ pageCfg.hiwContentKey } className={'mt60m'} />
        <DividingLine className="desktop" />

        <section className="services-section">
          <Holder type={1} className='services-holder'>
            <div className="services-title">
              { pageCfg.servicesTitle ||
              <>
                That’s why we are Awesome!
                {/*<div className="services-highlight">vs</div>*/}
              </>
              }
            </div>

            <div className="table-container">
              <div className="table-row heading">
                <div className="row-item row-item2">
                  {isMobile ?
                    <img
                      className="mobile"
                      width="29" height="29"
                      src={require('../../assets/img/logo-short.svg')}
                      alt=''
                    />
                    :
                    <img
                      className="desktop"
                      width="110" height="28"
                      src={require('../../assets/img/logo.svg')}
                      alt=''
                    />
                  }
                </div>
                <div className="row-item row-item3">Other<br /> services</div>

              </div>
              <div className="frame">
                {services.map((item, index) => {
                  return (
                    <div className="table-row" key={`service_${index}`}>
                      <div className="row-item row-item1">{item.name}</div>
                      <div className="row-item row-item2">
                        <img
                          className="icon"
                          width="18" height="18"
                          src={item.AT ? require('./img/ic_check-positive.svg') : require('./img/ic_check-negative.svg')}
                          alt=""
                        />
                      </div>
                      <div className="row-item row-item3">
                        <img
                          className="icon"
                          width="18" height="18"
                          src={item.other ? require('./img/ic_check-positive.svg') : require('./img/ic_check-negative.svg')}
                          alt=""
                        />
                      </div>
                    </div>
                    );
                })}
              </div>
            </div>

          </Holder>
        </section>


        <TextBackBlock2 />

        <TopExpertsUniversity />

        <Note type={1} />

        <Holder type={1}>
          <DividingLine type={'short1'} />
        </Holder>

        <Feedback feedbackItemsKey = {pageCfg.feedbackItemsKey} />

        <section className="result-section">
          <Holder type={1}>
            <div className="result-wrapper">
              <div className="result-title">We stand by your results being<br className="desktop"/>{' '}
                nothing less than awesome!</div>
              <div className="result-list">
                <div className="result-item">
                  <div className="result-item-title">300+</div>
                  <div className="result-item-subtitle">college subjects</div>
                </div>
                <div className="result-item">
                  <div className="result-item-title">2500+</div>
                  <div className="result-item-subtitle">study experts</div>
                </div>
                <div className="result-item">
                  <div className="result-item-title">96%</div>
                  <div className="result-item-subtitle">"excellent" chat ratings</div>
                </div>
              </div>
            </div>
          </Holder>
        </section>

        <TextBackBlock />

        <FAQ faqItemsKey = {pageCfg.faqItemsKey || 'faqItemsMain'} />

{/*
        {!!isJoinModalOpen &&
        <JoinModal onClose={onAppModalClose}/>
        }
*/}

        {pageCfg.thankYouModal &&
        <ThankYouModal />
        }
      </main>

      <Footer />

    </LocalesPage>
  )
}
