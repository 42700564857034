import React from 'react';

import classnames from 'classnames';
import './index.css';

export default ({ type, className, onClick, children }) => (
  <div
    className={classnames('overlay', {[`overlay_${type}`]:type}, className )}
    onClick={onClick}
  >
    {children}
  </div>
);
