import React from 'react';
import ContentPage from '../';
import { REACT_APP_CONTACT_EMAIL } from '../../../../../config';
//import { NavLink } from 'react-router-dom';

export default () => (
  <ContentPage>
    <h1 className="content-title">Cookie Policy</h1>

    <p className="content-p">On our websites, apps, electronic communications, advertisements, and other online services (collectively, the "Services"), we and our affiliates, third parties, and other partners use cookies and other identification technologies for a variety of purposes, such as authenticating users, remembering user preferences and settings, determining the popularity of the content, delivering and measuring the effectiveness of advertising campaigns, and examining website traffic. More information regarding the sorts of cookies we use, our motivations for doing so, and your options can be found here.</p>

    <h2 className="content-h2">Cookies Overview</h2>

    <p className="content-p">Cookies are small bits of data that are stored on your browser or device (personal computer, tablet, mobile phone, etc.) that are used to access websites in order to recognize repeat users. There are different types of cookies we use depending on their purpose. Cookies served by the entity that operates the domain you are visiting are called “first-party cookies.” the Awesome Tutors platform uses exactly this type while you are on https://awesome-tutors.com. Cookies served by companies that are not operating the domain you’re visiting are called “third-party cookies.” It happens, for example, when you allow Google to set a cookie on your browser while you visit https://awesome-tutors.com, so it is performed by a server located outside the domain of our website. Cookies may also endure for different periods of time. “Session cookies” are temporary and are deleted automatically upon closing the browser. The last type is “persistent cookie” which survives after your browser is closed, but can be deleted manually. They may recognize your device when you reopen your browser and browse the internet again.</p>

    <div className="content-h2">Other Identification Technologies</div>

    <p className="content-p">We call this a Cookie Policy, but the statement addresses cookies and other identification technologies we may use or permit on our Services. Among other identification technologies we use are pixel tags, local storage, and software development kits.</p>

    <p className="content-p">“Pixel tags” (also known as pixels or beacons) are small blocks of code installed on (or called by) a webpage, app, or ad which can access certain information about your device and browser, for example, device type, operating system, browser type, and version, website visited time of visit, referring website, IP address, and other similar information, including the cookie that identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors of that domain, typically with the permission of the domain owner.</p>

    <p className="content-p">“Local storage” refers generally to other places on a browser or device where information can be stored by websites, ads, or third parties (such as HTML5 local storage and browser cache).</p>

    <p className="content-p">“Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and in such a way allows the partner to acquire certain information about user interaction with the app, user device, and network.</p>

    <div className="content-h2">Your Rights</div>

    <p className="content-p">You, as a user, have the right to choose whether to accept cookies or not. However, they are an important part of how our Services work. You should be aware that a refusal to install or removal of cookies may affect the availability and functionality of the Services.</p>

    <p className="content-p">Most web browsers are set to accept cookies by default. If you prefer, you can change the settings so that the browser removes or rejects cookies. Please, visit the "Help" or “Preferences” menu of your browser for more information on how to manage this option. Some third parties also provide the ability to reject their cookies directly by clicking on an opt-out link. We have indicated in which cases this is possible in the table below.</p>

    <p className="content-p">Removing or rejecting browser cookies does not necessarily affect the third-party flash cookies which may be used by us or our partners in connection with our Services. You can get more information on cookies, check what cookies have been set on your device and how to manage them, here: www.allaboutcookies.org or www.youronlinechoices.eu.</p>

    <div className="content-h2">Types and Purposes of Cookies</div>

    <p className="content-p">The following table presents different kinds of cookies that our Services use and explains why we use them. The lists of the third-party cookie providers are intended merely as illustrative, not comprehensive.</p>

    <table className="content-table">
      <tr>
        <th className="column column1">Type of Cookie</th>
        <th className="column column2">Purpose</th>
        <th className="column column3">Who Serves<br/>
          (example)
        </th>
      </tr>
      <tr>
        <td className="column column1">Localization</td>
        <td className="column column2">Helps awesome-tutors.com provide a localized experience. For example, we may store information in a cookie that will allow seeing the website in a certain language basing on your location.</td>
        <td className="column column3">awesome-tutors.com</td>
      </tr>
      <tr>
        <td className="column column1">Website features and Services</td>
        <td className="column column2">Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
        <td className="column column3">awesome-tutors.com<br/>
          Facebook<br/>
          Twitter<br/>
          Google</td>
      </tr>
      <tr>
        <td className="column column1">Analytics and research</td>
        <td className="column column2">Help research, understand and improve products and Services. For example, when you access the awesome-tutors.com website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</td>
        <td className="column column3">Google<br/>
          MixPanel<br/>
          Yandex</td>
      </tr>
      <tr>
        <td className="column column1">Advertising</td>
        <td className="column column2">Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed. For more information about interest-based ads, or to opt-out of having your web-browsing information used for behavioral advertising purposes, please visit <a href="https://www.aboutads.info/choices" className="content-link">www.aboutads.info/choices</a> if you are in the United States, or <a href="http://youronlinechoices.eu/" className="content-link">http://youronlinechoices.eu/</a> if you are outside the United States.</td>
        <td className="column column3">Microsoft<br/>
          Yahoo<br/>
          Bing<br/>
          Twitter<br/>
          MixPanel</td>
      </tr>
    </table>

    <div className="content-h2">Contact us</div>
    <p className="content-p">If you have any questions about our cookie policy, please, contact us at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>. We are always glad to improve your user experience on our website.</p>

  </ContentPage>
)
