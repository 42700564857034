import React from 'react';

import classnames from 'classnames';
import './index.css';
import Holder from '../../components/Holder';
import DemoSubjects from '../../components/DemoSubjects';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icons';

export default ({ type }) => (
  <section className={classnames('top-section', {[`top-section_${type}`]:type} )}>
    <Holder type={1} className='top-holder'>
      <div className="top-title">What defines an awesome tutor for us?</div>
      <div className="top-message">
        We are looking for TOP subject experts ready to help with any assignment, subject, or topic you're struggling with.
        <br className="desktop"/>{' '}
        Our freelancers have at least 3 years of experience, backed by education from reputed institutions like the ones below.
      </div>

      <div className={classnames('university-block', {[`university-block_${type}`]:type})}>
          <div className="university-list">
            <img
              src={require('./img/1.png')}
              srcSet={require('./img/1@2x.png') + ' 2x'}
              width="125" height="33"
              alt=""/>
            <img
              src={require('./img/2.png')}
              srcSet={require('./img/2@2x.png') + ' 2x'}
              width="67" height="57"
              alt=""/>
            <img
              src={require('./img/3.png')}
              srcSet={require('./img/3@2x.png') + ' 2x'}
              width="53" height="65"
              alt=""/>
            <img
              src={require('./img/4.png')}
              srcSet={require('./img/4@2x.png') + ' 2x'}
              width="60" height="61"
              alt=""/>
            <img
              src={require('./img/5.png')}
              srcSet={require('./img/5@2x.png') + ' 2x'}
              width="107" height="29"
              alt=""/>
          </div>

      </div>

      <DemoSubjects oneRow={true} className={'pt40'}/>

      <Link to="/top-tutors" className="link-button top-tutors-link">
        <span className="message">Meet our TOP experts</span>
        <Icon
          className={'svg-icon'}
          iconName={'ic_arrow-right'}
        />
      </Link>

    </Holder>
  </section>
);
