import Landing from './pages/Landing';
import HowTutoringWorks from './pages/HowTutoringWorks';
import AboutUs from './pages/AboutUs';
import TopTutors from './pages/TopTutors';
import BecomeATutor from './pages/BecomeATutor';
import ReportAbuse from './pages/ContentPage/ReportAbuse';
import Terms from './pages/ContentPage/Terms';
import HonorCode from './pages/ContentPage/HonorCode';
import Privacy from './pages/ContentPage/Privacy';
import Cookies from './pages/ContentPage/Cookies';
import RefundPolicy from './pages/ContentPage/RefundPolicy';
import ThankYou from './pages/ThankYou';
/*
import Files from './pages/Files';
import Redirect from './pages/Redirect';
*/
import NotFound from './pages/ContentPage/404';
// import { IS_PROD } from '../../config';

import './assets/css/index.css';
import React from 'react';

export const viewport = 'width=device-width';

export const routes = [
  {
    path: '/',
    meta: {
      title: 'Awesome Tutors | On-demand tutoring platform',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best tutor to help you out.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help, homework help',
      robots: 'index, follow',
      // canonical: 'https://awesome-tutors.com',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      hiwContentKey: 'hiwContentMain',
      feedbackItemsKey: 'feedbackItemsMain',
/*
      landingGuid: {
        us: 'awesome-tutors.com_main',
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'awesome-tutors.com_main',
        },
      }),
*/
    },
  },
  {
    path: '/1',
    meta: {
      title: 'Awesome Tutors | Get urgent help from tutors in 300+ subjects',
      description: 'Text us and get connected to our customer care managers in 15 sec. Your manager will pick the perfect tutor for your current needs!',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/1',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      textbackCheckbox: true,
      hiwContentKey: 'hiwContentMain',
      feedbackItemsKey: 'feedbackItemsMain',
      landingGuid: {
        us: 'awesome-tutors.com_1',
      },
    },
  },
  {
    path: '/how-tutoring-works',
    meta: {
      title: 'Awesome Tutors | How Tutoring Works',
      description: 'Just text us and we\'ll arrange a session so you can gain skills to find answers, solve problems, and work independently!',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/how-tutoring-works',
    },
    component: HowTutoringWorks,
    exact: true,
    pageCfg: {
      feedbackItemsKey: 'feedbackItemsMain',
    },
  },

  {
    path: '/study-help-1',
    meta: {
      title: 'Awesome Tutors | Get on-demand help with writing tasks',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best writing expert to help you out.',
      keywords: 'awesome tutors, awesometutors, essay writing tips, help with essays, urgent help with essays, college essays, writing help, university essays',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/study-help-1',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Get urgent help with writing tasks',
      hiwContentKey: 'hiwContentStudyHelp1',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStudyHelp1',
      faqItemsKey: 'faqItemsStudyHelp1',
      landingGuid: {
        us: 'awesome-tutors.com_study',
      },
    },
  },
  {
    path: '/study-help-2',
    meta: {
      title: 'Awesome Tutors | Get on-demand help with writing tasks',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best writing expert to help you out.',
      keywords: 'awesome tutors, awesometutors, research paper, paper writing tips, help with papers, urgent help with papers, writing help, college papers, university papers',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/study-help-2',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Get urgent help with writing tasks',
      hiwContentKey: 'hiwContentStudyHelp2',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStudyHelp2',
      faqItemsKey: 'faqItemsStudyHelp2',
      landingGuid: {
        us: 'awesome-tutors.com_study',
      },
    },
  },
  {
    path: '/study-help-3',
    meta: {
      title: 'Awesome Tutors | Get on-demand help with homework',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best homework expert to help you out.',
      keywords: 'awesome tutors, awesometutors, homework helper, help with homework, urgent help with homework, homework help online, college homework, university homework, homework assistance',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/study-help-3',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Get urgent help with homework',
      hiwContentKey: 'hiwContentStudyHelp3',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStudyHelp3',
      faqItemsKey: 'faqItemsStudyHelp3',
      landingGuid: {
        us: 'awesome-tutors.com_study',
      },
    },
  },
  {
    path: '/study-help-4',
    meta: {
      title: 'Awesome Tutors | Get on-demand help with assignments',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best assignment expert to help you out.',
      keywords: 'awesome tutors, awesometutors, assignment helper, help with assignments, urgent help with assignments, college assignments, university assignments, online assignment, assignemnt tips',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/study-help-4',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Get urgent help with assignments',
      hiwContentKey: 'hiwContentStudyHelp4',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStudyHelp4',
      faqItemsKey: 'faqItemsStudyHelp4',
      landingGuid: {
        us: 'awesome-tutors.com_study',
      },
    },
  },
  {
    path: '/study-help-5',
    meta: {
      title: 'Awesome Tutors | Get on-demand help with homework',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best homework expert to help you out.',
      keywords: 'awesome tutors, awesometutors, summary writing help, thesis writing tips, help with discussion post, college homework, university homework',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/study-help-5',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Get urgent help with homework',
      hiwContentKey: 'hiwContentStudyHelp5',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStudyHelp5',
      faqItemsKey: 'faqItemsStudyHelp5',
      landingGuid: {
        us: 'awesome-tutors.com_study',
      },
    },
  },

  {
    path: '/math',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Math experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Math, Algebra or Geometry expert to help you.',
      keywords: 'awesome tutors, awesometutors, math help, geometry help, math helper, math homework help, math online help, solving math problems, calculus help',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/math',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with Math tasks?',
      secondTitle: <>Get help from<br className="mobile"/> <span className="highlight">Math, Algebra or Geometry</span> expert</>,
      hiwContentKey: 'hiwContentMath',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsMath',
      faqItemsKey: 'faqItemsMath',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/statistics',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Statistics experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Statistics expert to help you.',
      keywords: 'awesome tutors, awesometutors, statistics helper, statistics homework help, statistics online help, solving statistics problems, help with statistics assignments',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/statistics',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with Statistics?',
      secondTitle: <>Get help from <span className="highlight">Statistics</span> expert</>,
      hiwContentKey: 'hiwContentStatistics',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsStatistics',
      faqItemsKey: 'faqItemsStatistics',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/humanities',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Humanities expert',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best History, Philosophy or Nursing expert to help you.',
      keywords: 'awesome tutors, awesometutors, Humanities homework help, Philosophy homework help, Nursing paper, Nursing assignment, History essay, History homework help, Ethics paper, Arts, Religion essay, Anthropology homework, Culture, Theology, Public Health, Education',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/humanities',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with homework?',
      secondTitle: <>Get help from any <span className="highlight">Humanities</span> expert</>,
      hiwContentKey: 'hiwContentHumanities',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsHumanities',
      faqItemsKey: 'faqItemsHumanities',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/english-literature',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from English & Literature experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best English or Literature expert to help you out.',
      keywords: 'awesome tutors, awesometutors, help with English writing, help with English homework, English assignments, Linguistics homework help, Literature writing help, Literature review help, Creative writing',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/english-literature',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with writing tasks?',
      secondTitle: <>
        Get help from <br className="mobile"/> <span className="highlight">English or Literature</span> expert</>,
      hiwContentKey: 'hiwContentEnglishLiterature',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsEnglishLiterature',
      faqItemsKey: 'faqItemsEnglishLiterature',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/finance-economics-accounting',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Finance experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Finance, Economics or Accounting expert to help you.',
      keywords: 'awesome tutors, awesometutors, Economics homework help, Finance assignment, Accounting homework help, Accounting project help, Financial Accounting, Microeconomics, Political Economy',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/finance-economics-accounting',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with homework?',
      secondTitle: <>Get help from <span className="highlight">Finance, Economics<br className="desktop"/> or Accounting</span> expert</>,
      hiwContentKey: 'hiwContentFinanceEconomicsAccounting',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsFinanceEconomicsAccounting',
      faqItemsKey: 'faqItemsFinanceEconomicsAccounting',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/natural-sciences',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Natural Sciences experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Physics, Chemistry or Biology expert to help you.',
      keywords: 'awesome tutors, awesometutors, Chemistry homework help, Chemistry lab, help with Chemistry assignments, Physics homework help, help with Physics, Biology assignment, Science homework',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/natural-sciences',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with homework?',
      secondTitle: <>Get help from <span className="highlight">Physics, Chemistry or Biology</span> expert</>,
      hiwContentKey: 'hiwContentNaturalSciences',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsNaturalSciences',
      faqItemsKey: 'faqItemsNaturalSciences',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/business',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Business experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Business, Marketing or Management expert to help you.',
      keywords: 'awesome tutors, awesometutors, Business homework, Business Analytics help, Marketing assignment, Management homework help, help with Business Law, Project Management help',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/business',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with homework?',
      secondTitle: <>Get help from <span className="highlight">Business, Marketing<br className="desktop"/> or Management</span> expert</>,
      hiwContentKey: 'hiwContentBusiness',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsBusiness',
      faqItemsKey: 'faqItemsBusiness',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },
  {
    path: '/social-sciences',
    meta: {
      title: 'Awesome Tutors | Get on-demand help from Social Sciences experts',
      description: 'Get urgent study help with any task or topic! Just text us and we\'ll find the best Sociology, Psychology or Law expert to help you.',
      keywords: 'awesome tutors, awesometutors, Sociology homework help, Psychology paper, Psychology writing help, Law essay, Political Science, writing help',
      robots: 'noindex, nofollow',
      // canonical: 'https:/awesome-tutors.com/social-sciences',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      title: 'Need urgent help with homework?',
      secondTitle: <>Get help from any <span className="highlight">Social Sciences</span> expert</>,
      hiwContentKey: 'hiwContentSocialSciences',
      hideManagerSection: true,
      // servicesTitle: <>Awesome Tutors <div className="services-highlight">vs</div> other services</>,
      feedbackItemsKey: 'feedbackItemsSocialSciences',
      faqItemsKey: 'faqItemsSocialSciences',
      landingGuid: {
        us: 'awesome-tutors.com_subject',
      },
    },
  },



  {
    path: '/about-us',
    meta: {
      title: 'Awesome Tutors | About Us',
      description: 'Awesome Tutors is a platform providing effective online tutoring from experts in their fields.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/about-us',
    },
    component: AboutUs,
    pageCfg: {
    },
  },
  {
    path: '/top-tutors',
    meta: {
      title: 'Awesome Tutors | TOP Tutors',
      description: 'Meet our top-rated performers! Although all our tutors are awesome, these experts have particularly impressed us over recent months.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/top-tutors',
    },
    component: TopTutors,
    pageCfg: {
    },
  },
  {
    path: '/become-a-tutor',
    meta: {
      title: 'Awesome Tutors | Become a Tutor',
      description: 'We are looking for individuals willing to share knowledge at Awesome Tutors. Only for candidates with at least 3+ years of tutoring experience!',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/become-a-tutor',
    },
    component: BecomeATutor,
    pageCfg: {
    },
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Awesome Tutors | Report Abuse',
      description: 'If you believe that the tutor assigned to your request agreed to provide unethical help, please, report them for abuse.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/report-abuse',
    },
    component: ReportAbuse,
    pageCfg: {
    },
  },
  {
    path: '/terms',
    meta: {
      title: 'Awesome Tutors | Terms and Conditions',
      description: 'By using the Awesome Tutors platform you accept and agree to be bound and abide by these Terms and our Privacy Policy.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/terms',
    },
    component: Terms,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Awesome Tutors | Privacy Policy',
      description: 'This section will help you better understand how the Awesome Tutors platform collects, stores, processes, and uses your personal information.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/privacy-policy',
    },
    component: Privacy,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/cookies',
    meta: {
      title: 'Awesome Tutors | Cookie Policy',
      description: 'This section will help you better understand what cookies are and how the Awesome Tutors platform uses them.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/cookies',
    },
    component: Cookies,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Awesome Tutors | Honor Code',
      description: 'The Awesome Tutors platform does not condone and will not be involved in facilitating academic dishonesty.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/honor-code',
    },
    component: HonorCode,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/refund-policy',
    meta: {
      title: 'Awesome Tutors | Refund Policy',
      description: 'All refund requests are to be submitted within 60 days after the tutoring service was provided. If the service did not meet your requirements, let us know.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/refund-policy',
    },
    component: RefundPolicy,
    exact: true,
    pageCfg: {},
  },


  {
    path: '/thank-you',
    meta: {
      title: 'Awesome Tutors | Thank you',
      description: 'Thanks for reaching out! We\'ll get in touch with you in a few sec.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/thank-you',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
    },
  },
/*
  {
    path: '/thank-you',
    meta: {
      title: 'Awesome Tutors | Thank you',
      description: 'Thanks for reaching out! We\'ll get in touch with you in a few sec.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
      // canonical: 'https://awesome-tutors.com/thank-you',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      thankYouModal: true
    },
  },
*/

  {
    meta: {
      title: 'Awesome Tutors | Page not found',
      description: 'We are sorry, the page you are looking for does not exist.',
      keywords: 'awesome tutors, awesometutors, on-demand tutoring, urgent online tutoring, college help service, find a tutor, college advisor, study help, research help, presentation help, college help, university help',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
