import React from 'react';

import classnames from 'classnames';
import './index.css';
import Holder from '../../components/Holder';
import { TextBack } from '../../components/TextBack';
import { NavLink } from 'react-router-dom';
//import { useMobile } from '../../../../hooks';

export default ({ type, className, title, ...props }) => {
  //const isMobile = useMobile();
  return(
    <section
      id="textback"
      className={classnames('textback-block2', {[`textback-block2_${type}`]:type}, className )}
    >
      <Holder type={1} className={'textback-block2-holder'}>
        <div className="textback-block2-inner">
          <div className="textback-block2-title">{title || "Send your request and get an SMS in 15 sec!"}</div>

          <div className="textback">
            <div className="textback__wrapper">
              <TextBack {...props} />
              <div className="agree">
                No spam. By clicking the button, you agree to{' '}
                <NavLink className="link" to="/terms">Terms</NavLink>
                {' '}&{' '}
                <NavLink className="link" to="/privacy-policy">Privacy Policy</NavLink>.
              </div>
            </div>
          </div>

        </div>
      </Holder>
    </section>
  )
};
