import React from 'react';
import classnames from 'classnames';
import Icon from '../Icons';
import './BubbleSpeech.css';

export default ({ type, children }) => (
  <div className={classnames("bubble", {"outgoing": type === "A"}, {"incoming": type === "C"})}>
    {children}
  </div>
);
