import React from 'react';
import { NavLink } from 'react-router-dom'
import ContentPage from '../';
//import {REACT_APP_CONTACT_EMAIL} from "../../../../config";
import './index.css';

export default () => (
  <ContentPage noWrap className={'not-found'}>
    <img
      className="not-found-img"
      width="160"
      height="160"
      src={require('./img/404.png')}
      srcSet={require('./img/404@2x.png') + ' 2x'}
      alt=""/>
    <h1 className="title">Oops, the page you are looking for does not exist</h1>
    <NavLink className="link-button4 type1" to='/'>Go back to homepage</NavLink>
  </ContentPage>
)
