// /math
export const feedbackItemsMath = [
  [
    {
      type: "C",
      message: "Just wanted to give a shoutout to your expert. They made math problems seem less daunting. Big thanks to them!",
    },
    {
      type: "A",
      message: "I'm so glad to hear that. If you encounter more math challenges, you know where to reach out!",
    },
    {
      type: "C",
      message: "Absolutely, your expert's a math whiz. Catch you later! 👋",
    },
  ],
  [
    {
      type: "C",
      message: "You guys rock! You’ve helped more than I can ever hope for. You know, Math has never been my strongest subject to the point that I barely got through high school :((((  But thanks to Sylvia I started believing in myself!!",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "Hello there, how did you like the help you received from your assigned expert? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "10! Jamie had this knack for simplifying all those shapes and angles, making geometry way less puzzling.🔥🔥 I doubted that we'd have enough time but I am very satisfied with the outcome.",
    },
  ],
  [
    {
      type: "C",
      message: "I just wanted to drop a quick note about my last request – it was a total win. Your expert seriously aced it helping me tackle algebra.",
    },
    {
      type: "C",
      message: "But I think I need at least 1 more session. I had a class an hour ago and now I have a new math problem I'm struggling with 😟",
    },
    {
      type: "C",
      message: "Can I book a session with Dylan for tomorrow morning?",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "So, I'm diving into these new calculations, and I'm hitting a bit of a roadblock. Can you help me untangle this mess? 🙏",
    },
    {
      type: "C",
      message: "Can you assign me to Mike again pls? Love that dude!",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
