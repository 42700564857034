import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
// import { HashLink } from 'react-router-hash-link';
// import { NavLink } from 'react-router-dom';
// import Swipe from 'react-easy-swipe';
import Slider from 'react-slick';
// import {CSSTransition} from "react-transition-group";
import classnames from 'classnames';

import { useMobile } from '../../../../hooks';
// import Overlay from '../Modal/Overlay';
import Holder from '../../components/Holder';
import Icon from '../../components/Icons';
import BubbleSpeech from '../../components/BubbleSpeech';
import { hiwContentMain } from './hiwContent/hiwContentMain';
import { hiwContentStudyHelp1 } from './hiwContent/hiwContentStudyHelp1';
import { hiwContentStudyHelp2 } from './hiwContent/hiwContentStudyHelp2';
import { hiwContentStudyHelp3 } from './hiwContent/hiwContentStudyHelp3';
import { hiwContentStudyHelp4 } from './hiwContent/hiwContentStudyHelp4';
import { hiwContentStudyHelp5 } from './hiwContent/hiwContentStudyHelp5';
import { hiwContentMath } from './hiwContent/hiwContentMath';
import { hiwContentStatistics } from './hiwContent/hiwContentStatistics';
import { hiwContentHumanities } from './hiwContent/hiwContentHumanities';
import { hiwContentEnglishLiterature } from './hiwContent/hiwContentEnglishLiterature';
import { hiwContentFinanceEconomicsAccounting } from './hiwContent/hiwContentFinanceEconomicsAccounting';
import { hiwContentNaturalSciences } from './hiwContent/hiwContentNaturalSciences';
import { hiwContentBusiness } from './hiwContent/hiwContentBusiness';
import { hiwContentSocialSciences } from './hiwContent/hiwContentSocialSciences';

import './hiw.css';
import '../../components/Slider/slickSlider.scss';

const hiwContent = {
  hiwContentMain,
  hiwContentStudyHelp1,
  hiwContentStudyHelp2,
  hiwContentStudyHelp3,
  hiwContentStudyHelp4,
  hiwContentStudyHelp5,
  hiwContentMath,
  hiwContentStatistics,
  hiwContentHumanities,
  hiwContentEnglishLiterature,
  hiwContentFinanceEconomicsAccounting,
  hiwContentNaturalSciences,
  hiwContentBusiness,
  hiwContentSocialSciences,
};

export const HowItWorks = ({ hiwContentKey, className }) => {
  const isMobile = useMobile();
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(null);

  const onStepClick = (step) => {
    setStep(step);
  };

  useEffect(() => {
    if (!isMobile) {
      if (timer) {
        clearTimeout(timer); // Clearing the active timer before creating a new one
      }

      const newTimer = setTimeout(() => {
        setStep((prevStep) => (prevStep === 3 ? 1 : prevStep + 1));
      }, 4000);

      setTimer(newTimer); // Saving a new timer ID
    }
  }, [step]);

  return (
    <section className={classnames('hiw-section', className )}>
      <div className="hiw-bg">
        <Holder className='hiw-holder'>
          <div className="hiw-title">{ hiwContent[hiwContentKey].hiwTitle }</div>
          <div className="hiw-wrapper">
            {isMobile ?
              <Slider
                variableWidth
                useTransform={false}
                //centerMode
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
                className={'main-slider'}
                //adaptiveHeight={true}
                swipe={true}
                arrows={false}
                dots={true}
                afterChange={(current) => setStep(current + 1)}
                customPaging={() => {
                  return (
                    <button className="slick-dots-btn" />
                  )
                }}
                autoplay={true}
                autoplaySpeed={4000}
              >
                {hiwContent[hiwContentKey].hiwItems.map((item, key) => {
                  return(
                    <div key={`hiv_${key}`}>
                      <div
                        className={classnames("hiw-item",{"active": step === item.number})}
                      >
                        <div className="hiw-item-number">{item.number}</div>
                        <div>
                          <div className="hiw-item-title">{item.title}</div>
                          <div className="hiw-item-message">{item.message}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>

              :

              <div className="hiw-list">
                {hiwContent[hiwContentKey].hiwItems.map((item, key) => {
                  return(
                    <div
                      className={classnames("hiw-item",{"active": step === item.number})}
                      onClick={() => onStepClick(item.number)}
                      key={`hiv_${key}`}
                    >
                      <div className="hiw-item-number">{item.number}</div>
                      <div>
                        <div className="hiw-item-title">{item.title}</div>
                        <div className="hiw-item-message">{item.message}</div>
                      </div>
                    </div>
                  )
                })}
                <Link to="/how-tutoring-works" className="link-button">
                  <span className="message">Learn more</span>
                  <Icon
                    className={'svg-icon'}
                    iconName={'ic_arrow-right'}
                  />
                </Link>
              </div>
            }

            <div className="phone-block">
              <div className="phone-inner">

                {hiwContent[hiwContentKey].hiwItems.map((item, key) => {
                  return(
                    <React.Fragment key={`hiw_item_${key}`}>
                      {(step >= item.number) &&
                      <>
                        {item.slide.map((slideItem, slideItemKey) => {
                          if (slideItem.type === 'img') {
                            return(
                              <img
                                key={`hiw_img_${slideItemKey}`}
                                className={classnames("step-screen", {'with-border':slideItem.withBorder})}
                                width={ slideItem.width }
                                height={ slideItem.height }
                                src={require(`./img/${slideItem.file}`)}
                                alt=""/>
                            )
                          } else return (
                            <BubbleSpeech
                              key={`hiw_img_${slideItemKey}`}
                              type={slideItem.type}
                            >
                              { slideItem.message }
                            </BubbleSpeech>
                          )
                        })}
                     </>
                    }
                    </React.Fragment>
                  )
                })}

              </div>
            </div>
          </div>

        </Holder>
      </div>

      {isMobile &&
        <Holder className='hiw-link-holder'>
          <Link to="/how-tutoring-works" className="link-button mobile">
            <span className="message">Learn more</span>
            <Icon
              className={'svg-icon'}
              iconName={'ic_arrow-right'}
            />
          </Link>
        </Holder>
      }
    </section>
  );
};
