import React from 'react';
// import { Link } from 'react-router-dom'
import Holder from '../../components/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import { Join } from '../../blocks/Join';
import { Manager } from '../../blocks/Manager';
import TextBackBlock2 from '../../blocks/TextBackBlock2';

import { ThankYouModal } from '../../blocks/Modal/ThankYouModal';

import LocalesPage from '../LocalesPage';

import { useMobile } from '../../../../hooks';

import './about.css';

export default (props) => {
  const isMobile = useMobile();
  const { pageCfg } = props;

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="about-hero">
          <Holder type={1} className={'about-hero-holder'}>
            <div className="about-hero-block">
              <h1>
                <span className="about-hero-title">One day our<br className="mobile"/> founders said:</span><br />
                <span className="about-hero-message">“Let’s make a process of finding a tutor stressless and convenient from A to Z, who’s up for the challenge?”</span>
              </h1>
              <h2 className="about-hero-subtitle">...that’s when our<br className="mobile"/> story began.</h2>
            </div>
            {!isMobile &&
              <img
                className="desktop"
                width="700"
                height="388"
                src={require('./img/about.png')}
                srcSet={require('./img/about@2x.png') + ' 2x'}
                alt=""/>
            }
          </Holder>
        </section>

        <section className="about-block">
          <Holder type={1} className={'about-block-holder'}>
            <p className="about-p1">No doubt, tutoring is not only essential for students aiming for academic excellence but also anticipates their problems. But did you know most students give up on that idea when <span className="highlight highlight1">looking for the right match</span> on freelance platforms or online tutoring websites? If you've ever been there, well, you’re not alone. To break this vicious circle we've developed a simple solution!</p>
            <p className="about-p2">Why burden yourself with choices, when AT care managers are specially<br className="desktop"/> trained to find you the <span className="highlight highlight2">right tutor?</span></p>
          </Holder>
        </section>

        <Manager />

        <TextBackBlock2 />

        <Join className={'mt60'}/>
        {/*
        {!!isJoinModalOpen &&
        <JoinModal onClose={onAppModalClose}/>
        }
*/}

        {pageCfg.thankYouModal &&
        <ThankYouModal />
        }
      </main>

      <Footer />

    </LocalesPage>
  )
}
