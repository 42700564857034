import React, { useEffect } from 'react';

//import Swipe from 'react-easy-swipe';
//import {CSSTransition} from "react-transition-group";
import { useHistory } from 'react-router-dom';
//import classnames from 'classnames';

//import { useMobile } from '../../../../hooks';
import Overlay from '../Overlay';

import './thank-you.css';

export const ThankYouModal = ({type}) => {
  //const isMobile = useMobile();
  //const [isMenuOpen, setMenuOpen] = useState(0);
  const history = useHistory();

  useEffect(() => {
    document.querySelector('html').classList.add('fixed');
  });

  const onClickGotIt = () => {
    document.querySelector('html').classList.remove('fixed');
    history.push('/');
  };

  return (
    <Overlay>
      <div className="thank-modal">
        <div className="thank-title">Thanks for trusting us!</div>
        <div className="thank-message">We'll text you in a bit
          <img
            className="thank-icon"
            src={require('./img/smile-wink-icon.png')}
            srcSet={require('./img/smile-wink-icon@2x.png') + ' 2x'}
            alt=""/>
        </div>
        <button className="button thank-button" onClick={onClickGotIt}>Got it</button>
        <img className="thank-img" src={require('./img/thank-image.svg')} alt=""/>
      </div>
    </Overlay>
  );
};
