import React, { useState, useEffect } from 'react';
import MainModal from '../MainModal';
//import classnames from 'classnames';
//import { useMobile } from '../../../../hooks';

import './join-modal.css';

import useForm from "../../../hooks/useForm";
import classnames from 'classnames';

export const JoinModal = ({onClose}) => {
  const [isJoinSuccessModalOpen, setJoinSuccessModalOpen] = useState(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submit, validate);

  useEffect(() => {
    document.querySelector('html').classList.add('fixed');
  });

  function onJoinModalClose() {
    document.querySelector('html').classList.remove('fixed');
    onClose();
  }

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = 'Please, fill out this field!';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    return errors;
  }

  function submit() {
    //console.log('No errors, submit callback called!');
    setJoinSuccessModalOpen(true);
    //onClose();
    //history.push('/thank-you');
  }

  return (
    <>
      {!isJoinSuccessModalOpen ?
      <MainModal type={'logo'} className={'join-modal'}>
        <div className="join-modal-message">Please enter your email so that we can send you an application form along with detailed information about the recruitment process.</div>
        <form className="form" noValidate onSubmit={handleSubmit}>
          <input
            id="email"
            type="text"
            className={classnames('form-input', {'error':errors.email})}
            placeholder="Email"
            value={values.email || ''}
            onChange={handleChange}
            required
          />
          {errors.email && (
            <div className="form-item-error">{errors.email}</div>
          )}
          <button className="link-button4 type2" type="submit">Get an application</button>
          <button className="link-button5" type="button" onClick={onJoinModalClose}>Cancel</button>
        </form>
      </MainModal>

      :

      <MainModal className={'join-success-modal'}>
        <img
          className="join-success-img"
          width="58" height="48"
          src={require('./img/modal-img.svg')}
          alt=""/>
        <div className="join-success-message">Thanks for applying! We'll send you an email with details in 5-7
          business days.
        </div>
        <button className="link-button4 type2" onClick={onJoinModalClose}>Got it</button>
      </MainModal>
      }
      </>
  );
};
