import React from 'react';

import classnames from 'classnames';
import './index.css';
import Holder from '../../components/Holder';
import ResponsiveSlider from '../../components/Slider';

export default ({ type, feedbackItemsKey }) => (
  <section className={classnames('feedback-section', {[`feedback-section_${type}`]:type})}>
    <Holder type={1} className="feedback-holder">
      <div className="feedback-title">
        Check out some
        <div className="feedback-highlight">latest feedback</div>
        from students
      </div>
      <ResponsiveSlider feedbackItemsKey={feedbackItemsKey} />

    </Holder>
  </section>
);
