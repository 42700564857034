import React, { Component, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
//import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { captureException } from '@sentry/browser';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { trackValues } from '../../lrtracker';
import Icons from './../../base_components/Icons';

import * as utils from './../../utils';
import { RippleButton } from './../../components/Ripple';
import tracker from '../../lrtracker';
import { healthCheck } from './../../redux/actions';

import {
  checkLeadExists,
  createLead,
  getUserData,
  sentTextBack,
  validatePhone,
} from './../../redux/actions';
import {
  REACT_APP_TEXT_BACK_TEXT,
  REACT_APP_TEXT_BACK_TEXT_LEAD_EXIST,
  REACT_APP_SITENAME,
  //REACT_APP_BUILD_ID,
} from '../../config';
import { parseQuery } from './../../utils';

export const TextBack = ({ className, children }) => (
  <div className={classnames('textback', className)}>{children}</div>
);

const withTextBackConnect = (WrappedComponent) => {
  const TextBackConnected = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      dispatch(healthCheck()).catch((error) => {
        history.push('/healthcheck');
      });
    }, []);

    const mergeProps = { ...props, location, history };

    return <WrappedComponent {...mergeProps} />;
  };

  const mapStateToProps = (state, ownProps) => {
    const locales = state.locales;
    const textback = state.textback;

    const { isPhoneError, error } = textback;

    const { data, isLocaleSet } = locales;

    return {
      localeData: data,
      isLocaleSet,
      isPhoneError,
      serverError: error,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      validatePhone: (phone) => dispatch(validatePhone(phone)),
      checkLeadExists: (phone) => dispatch(checkLeadExists(phone)),
      getUserData: (phone) => dispatch(getUserData(phone)),
      createLead: (data) => dispatch(createLead(data)),
      sentTextBack: (phone, source_url, textBackBody, userId) =>
        dispatch(sentTextBack(phone, source_url, textBackBody, userId)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(TextBackConnected);
};

class TextBackFormComponent extends Component {
  constructor(props) {
    super(props);

    this._handleOnSubmit = this._handleOnSubmit.bind(this);
    this._handleOnChange = this._handleOnChange.bind(this);
    this.send = this.send.bind(this);
    this._sentTextBack = this._sentTextBack.bind(this);
    this._validate = this._validate.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      phone: '',
      error: false,
      isFetching: false,
      isFocused: false,
      isValidPhone: false,
      timer: 0,
      isSubmitBtnDisabled: true,
      testPhone: '',
      //landing_guid: '',
      landing_type: '',
      source_url: '',
    };
  }

  componentDidMount() {
    if (localStorage.getItem('leadCreated')) {
      localStorage.removeItem('leadCreated');
    }
    this.setPhone();
    this.initState();
  }

  componentDidUpdate() {
    const { testPhone, timer, isSubmitBtnDisabled } = this.state;

    if (!testPhone) {
      this.setPhone();
    }

    if (timer > 0) {
      setTimeout(
        () =>
          this.setState({
            timer: timer - 1,
          }),
        1000
      );
    } else if (isSubmitBtnDisabled) {
      this.setState({
        isSubmitBtnDisabled: false,
      });
    }
  }

  initState() {
    let landing_type;

    if (typeof window !== 'undefined') {
      let props = this.props.location.state;
      landing_type =
        (props && props.landing_type) ||
        _.get(parseQuery(window.location.search), 'landing_type', '') ||
        '';
      this.setState(
        {
          phone:
            (props && props.phone) ||
            _.get(parseQuery(window.location.search), 'to', '')
              .split('-')
              .join('') ||
            '',
          landing_type,
          //landing_guid: _.get(parseQuery(window.location.search), 'landing_guid', '') || 'stage.nerdifyit.com_en',
          source_url:
            (this.props.location.state &&
              this.props.location.state.source_url) ||
            _.get(parseQuery(window.location.search), 'source_url', '') ||
            window.location.href,
        },
        function () {
          if (this.state.source_url.includes('thank-you')) {
            this.props.history.push('/');
          }
        }
      );
    }

    if (landing_type === 'main') {
      this.setState({
        isSubmitBtnDisabled: true,
        timer: 15,
      });
      /*
    } else if (landing_type === 'second') {
    } else if (landing_type === 'undelivered') {
*/
    }
  }

  setPhone() {
    const { localeData } = this.props;
    this.setState({
      testPhone: window.btoa(
        REACT_APP_SITENAME + ' ' + utils.clearPhone(localeData.phone)
      ),
    });
  }

  _validate() {
    let res = /^(\+1|\+6)[0-9]{3}[0-9]{3}[0-9]{4}$/.test(this.state.phone);

    if (!res) {
      this.setState({
        error: true,
      });
      return false;
    } else {
      return true;
    }
  }

  async send() {
    const { phone } = this.state;
    const source_url = this.state.source_url; //.replace(/(^\w+:|^)\/\//, '');

    await this.props.validatePhone(phone);

    const { isPhoneError, pageCfg, localeData } = this.props;

    if (isPhoneError) {
      return false;
    }

    const existsData = await this.props
      .checkLeadExists(phone)
      .then((resp) => resp.data);

    if (existsData.result) {
      const userId = existsData._id;

      tracker.config.setCid(userId); // track exists
      trackValues('stage', 'textbackSentSuccessfully', { context: {} });

      this._sentTextBack(phone, source_url, userId).then((resp) => {
        if (resp.data.ok) {
          this.props.history.push({
            pathname: '/thank-you',
            search: this.props?.location?.search || '',
            state: {
              userId,
              landing_type: resp.data.landing_type,
              source_url,
              phone,
            },
          });
          this.initState();
          return resp;
        }
      });
    } else {
      const userData = await this.props.getUserData(phone);

      const data = {
        ...userData,
        textback_options: {
          to_num: phone,
          landing_guid: localeData.landingGuid,
          source_url: source_url,
          body: pageCfg?.textBackText || REACT_APP_TEXT_BACK_TEXT,
        },
      };

      const createLeadData = await this.props
        .createLead(data)
        .then((resp) => {
          if (resp.data?._id) {

            tracker.config.setCid(resp.data?._id); // track new
            trackValues('stage', 'textbackSentSuccessfully', { context: {} });

            this.props.history.push({
              pathname: '/thank-you',
              search: this.props?.location?.search || '',
              state: {
                userId: resp.data?._id,
                landing_type: 'main',
                source_url,
                phone,
              },
            });
            this.initState();
          }

          return resp;
        })
        .catch((error) => error);

      //const userId = createLeadData._id;
      //tracker.config.setCid(userId); // track new

      localStorage.setItem('leadCreated', true);
    }
  }

  _sentTextBack(phone, source_url, userId) {
    this.setState({
      error: false,
    });

    const { pageCfg } = this.props;

    const textBackBody =
      (pageCfg && pageCfg.textBackLeadExist) ||
      REACT_APP_TEXT_BACK_TEXT_LEAD_EXIST;

    return this.props.sentTextBack(phone, source_url, textBackBody, userId);
  }

  async _handleOnSubmit(e) {
    const { isFetching } = this.state;
    const { isTermsNotAccepted, handleTextbackSubmit } = this.props;

    e.preventDefault();

    if (isTermsNotAccepted) {
      handleTextbackSubmit();
      return false;
    }

    const isValidated = this._validate();

    if (!isValidated) {
      return false;
    }

    if (isFetching) {
      return;
    } else {
      this.setState({
        isFetching: true,
      });
    }

    try {
      await this.send()
        .then(() => {
          this.setState({
            isFetching: false,
            phone: '',
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error) {
      captureException(error);
    }
  } //=========================================================================
  _handleOnChange(e) {
    this.setState({
      phone: utils.clearPhone(e.target.value),
      timer: 0,
    });
  }

  handleFocus() {
    this.setState({
      isFocused: !this.state.isFocused,
      error: false,
    });
  }

  render() {
    const { isFetching, phone, isFocused, testPhone, isSubmitBtnDisabled } =
      this.state;
    // const { phone, isFocused, testPhone } = this.state;
    // const isFetching = true;
    const {
      textBackIcons,
      localeData,
      isPhoneError,
      renderSelectLocales,
      isLocaleSet,
      serverError,
    } = this.props;

    const isLocales = typeof renderSelectLocales === 'function';

    const error =
      isPhoneError || this.state.error
        ? 'Looks like you have mistyped your phone number. Please check and re-enter it.'
        : serverError && serverError.error_message;

    return (
      <div className="textback__form">
        <div className={`phone-block`}>
          <form
            className={`phone-form text-back-form ${error ? 'error' : ''} ${isFocused ? 'focus' : ''}`}
            id="text-back-form"
            onSubmit={this._handleOnSubmit}
            data-test-p={Buffer.from(localeData.landingGuid).toString('base64')}
          >
            <input type="hidden" name="csrfmiddlewaretoken" value="" />

            <div className="input-wrapper">
              {isLocales
                ? renderSelectLocales()
                : isLocaleSet && (
                    <Icons
                      className="svg-icon"
                      icons={textBackIcons}
                      iconName={localeData.flag}
                    />
                  )}

              <InputMask
                className="input-mask"
                type="tel"
                mask={isLocaleSet ? localeData.phoneMask : ''}
                value={phone}
                //maskChar='X'
                maskPlaceholder={null}
                //alwaysShowMask
                placeholder={localeData.phoneMask.replace(/9/g, 'X')}
                onChange={this._handleOnChange}
                onFocus={this.handleFocus}
                onBlur={this.handleFocus}
              />
              {error && <p className="error-line">{error}</p>}
            </div>
            <RippleButton
              className={classnames('button', 'submit-button', {
                'submit-button-loading': isFetching,
                disabled: isSubmitBtnDisabled,
              })}
              type="submit"
              data-gacat="text-back_request"
              data-gaact="bottom"
              disabled={isFetching || isSubmitBtnDisabled}
            >
              {isSubmitBtnDisabled && this.state.timer > 0 ? (
                `00:${('0' + this.state.timer).slice(-2)}`
              ) : isFetching ? (
                'Sending SMS...'
              ) : (
                <>{this.props.submitBtnTxt || 'Text me asap'}</>
              )}
            </RippleButton>
          </form>
        </div>
      </div>
    );
  }
}

export const TextBackForm = withTextBackConnect(TextBackFormComponent);
