import React from 'react';

import classnames from 'classnames';
import { HashLink } from 'react-router-hash-link';
import './index.css';
import Holder from '../../components/Holder';

export default ({ type, className }) => (
  <section className={classnames('note-block', {[`note-block_${type}`]:type}, className)}>
    <Holder type={1} className='note-holder'>
      Please note that Awesome Tutors is proud to support academic <br className="desktop"/>
        integrity and does not tolerate its violations. Read our <HashLink className="link" to="/honor-code">Honor Code</HashLink> and a <HashLink className="link" to="/honor-code#list">list of prohibited requests</HashLink>.
    </Holder>
  </section>
);
