// /study-help-3
export const feedbackItemsStudyHelp3 = [
  [
    {
      type: "A",
      message: "How was your last task? 😊",
    },
    {
      type: "C",
      message: "Your expert is gold! The dude broke down a tricky homework topic for me in like 30 min.",
    },
    {
      type: "A",
      message: "Happy to hear that! How did he explain the topic to you?",
    },
    {
      type: "C",
      message: "Dunno, I guess he just knew how to make complicated stuff feel simple. He explained everything step by step, and I just got it, you know!",
    },
  ],
  [
    {
      type: "C",
      message: "My assigned expert was absolutely fantastic in guiding me through my homework. She made what seemed like a daunting task feel so manageable, by breaking down the problem into steps that made sense. You guys rock, thanks a lot for finding Laura for me! 🔥",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "Hello there, how did you like the help you received from your assigned expert?",
    },
    {
      type: "C",
      message: "Your expert is just awesome! They helped me understand a tough homework topic. Danny taught me this clever technique for blending evidence into my arguments. I hope my homework will be at a higher level now 🔥",
    },
  ],
  [
    {
      type: "C",
      message: "The help I got from you was a game-changer for me!",
    },
    {
      type: "C",
      message: "Layla saved my day with her advice on a tricky homework problem.",
    },
    {
      type: "C",
      message: "Plus, those alternative approaches she threw in? Brilliant move 🔥🔥",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "Hi, I need an expert to help me with the sources for my homework task.",
    },
    {
      type: "C",
      message: "Can you book a session with Mike once again? He was like a source-seeking ninja last time, guiding me through the maze of finding material for my homework.",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
