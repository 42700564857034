// /study-help-2
export const feedbackItemsStudyHelp2 = [
  [
    {
      type: "A",
      message: "How was your last task? 😊",
    },
    {
      type: "C",
      message: "Hey, it was pretty nice. Your expert taught me how to find relevant sources for my papers.",
    },
    {
      type: "A",
      message: "Great! What did you like the most?",
    },
    {
      type: "C",
      message: "What I did appreciate most was that he showed me how to check sources for credibility, which is a true game changer for me!.",
    },
  ],
  [
    {
      type: "C",
      message: "My assigned expert totally helped me wrap my head around that essay topic. Dana talked me through it like a pro and gave real-life examples that made it click. Way less confusion now!",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne is the sweetest and smartest expert ever.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "Hey! How did you like the help you received from your assigned expert?",
    },
    {
      type: "C",
      message: "Pretty good. Mikaela shared some valuable writing tips with me. Not some generic stuff, but those that actually work!",
    },
    {
      type: "C",
      message: "She taught me this clever technique for blending evidence into my arguments. I hope my papers will be at a higher level now 🔥",
    },
  ],
  [
    {
      type: "C",
      message: "Just wanted to say that I'm super grateful for your expert's guidance. Finding sources for papers doesn't feel like a maze anymore!",
    },
    {
      type: "C",
      message: "But I need one more session ASAP. I have an English class next week and I am really struggling with my paper. Definitely need some writing advice here.",
    },
    {
      type: "C",
      message: "Can I book a session with Lyle, pls?",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "Can you assign me to Colleen again, pls?",
    },
    {
      type: "C",
      message: "She was so good at guiding me in structuring arguments for my paper last time.",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
