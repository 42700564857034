import React from 'react';

import classnames from 'classnames';
import './index.css';
import Holder from '../../components/Holder';
import { TextBack } from '../../components/TextBack';
import { NavLink } from 'react-router-dom';
import { useMobile } from '../../../../hooks';

export default ({ type, className, subtitle, ...props }) => {
  const isMobile = useMobile();
  return(
    <section className={classnames('textback-block1', {[`textback-block1_${type}`]:type}, className )}>
      <Holder type={1} className={'textback-block1-holder'}>
        <div className="textback-block1-inner">
          <div className="textback-block1-column">
            <div className="textback-block1-title">Still having questions?<br />
              Let’s chat!</div>
            <div className="textback-block1-subtitle">
              {subtitle || <>We’ll text you in just 15 seconds! <span role="img" aria-label="">😊</span></>}
            </div>

            <div className="textback">
              <div className="textback__wrapper">
                <TextBack {...props} />
                <div className="agree">
                  No spam. By clicking the button, you agree to{' '}
                  <NavLink className="link" to="/terms">Terms</NavLink>
                  {' '}&{' '}
                  <NavLink className="link" to="/privacy-policy">Privacy Policy</NavLink>.
                </div>
              </div>
            </div>

          </div>
          {!isMobile &&
          <img
            className="landing-hero-img"
            src={require('./img/hero-image2.png')}
            srcSet={require('./img/hero-image2@2x.png') + ' 2x'}
            alt=""/>
          }
        </div>
      </Holder>
    </section>
  )
};
