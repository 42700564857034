import React, { useEffect } from 'react';

import MainModal from '../MainModal';
//import classnames from 'classnames';
//import { useMobile } from '../../../../hooks';
import './login-modal.css';

import useForm from "../../../hooks/useForm";
import classnames from 'classnames';

export const LoginModal = ({onClose}) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submit, validate);

  useEffect(() => {
    document.querySelector('html').classList.add('fixed');
  });

  function onLoginModalClose() {
    document.querySelector('html').classList.remove('fixed');
    onClose();
  }

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = 'Please, fill out this field!';
    } else {
      errors.email = 'User not found';
    }
    return errors;
  }

  function submit() {
    //console.log('No errors, submit callback called!');
    document.querySelector('html').classList.remove('fixed');
    onClose();
  }

  return (
    <MainModal type={'logo'} className={'login-modal'}>
      <form className="form" noValidate onSubmit={handleSubmit}>
        <input
          id="email"
          type="text"
          className={classnames('form-input', {'error':errors.email})}
          placeholder="Email"
          value={values.email || ''}
          onChange={handleChange}
          required
        />
        {errors.email && (
          <div className="form-item-error">{errors.email}</div>
        )}
        <input
          id="password"
          type="password"
          className={classnames('form-input', {'error':errors.password})}
          placeholder="Password"
          value={values.password || ''}
          onChange={handleChange}
          required
        />
        <button className="link-button4 type1" type="submit">Log in</button>
        <button className="link-button5" type="button" onClick={onLoginModalClose}>Cancel</button>
      </form>
    </MainModal>
  );
};
