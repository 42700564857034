import React from 'react';

import {
  //TextBack as TextBackBase,
  TextBackForm as TextBackFormBase,
} from '../../../../base_components/TextBack';
// import { InputSelectLocales } from './InputSelect';
import textBackIcons from './img/icons.svg';

import './textback.css';

export const TextBack = (props) => (
  <TextBackFormBase
    {...props}
    textBackIcons={textBackIcons}
  >
  </TextBackFormBase>
);
