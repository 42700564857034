import React from 'react';
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import { useMobile } from '../../../../hooks';
import Holder from '../../components/Holder';
import DividingLine from '../../components/DividingLine';

import './manager.css';

export const Manager = ({ type, className }) => {
  const isMobile = useMobile();
  return (
    <section className={classnames("manager-block", {[`manager-block_${type}`]: type}, className)}>
      <Holder type={2} className={'manager-holder'}>
        <div className="manager-title">Based on your requirements and needs your personal manager:</div>
        <div className="manager-list">
          <div className="manager-item">
            <img width="36" height="36" src={require('./img/icon1.svg')} alt=""/>
            <div className="manager-message">Understands what type of tutor would benefit you best</div>
          </div>
          {!isMobile && <img className="manager-pointer desktop" width="41" height="6" src={require('./img/pointer.svg')} alt=""/>}
          <div className="manager-item">
            <img width="36" height="36" src={require('./img/icon2.svg')} alt=""/>
            <div className="manager-message">Contacts the best available tutor who matches the requirements</div>
          </div>
          {!isMobile && <img className="manager-pointer desktop" width="41" height="6" src={require('./img/pointer.svg')} alt=""/>}
          <div className="manager-item">
            <img width="36" height="36" src={require('./img/icon3.svg')} alt=""/>
            <div className="manager-message">Arranges a tutoring session for your{' '}
              <span className="nowrap">5-star</span> experience</div>
          </div>
        </div>
        {!isMobile && <DividingLine type={"short3"} className="desktop"/>}
        <div className="manage-subtitle">Yeap, we handle all the<br className="mobile"/>{' '}
          boring stuff.</div>
      </Holder>
    </section>
  );
};
