import React from 'react';
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import Holder from '../../components/Holder';
import './join.css';

export const Join = ({ type, className }) => {
  return (
    <section className={classnames('join-block', {[`join-block_${type}`]:type}, className )}>
      <Holder type={1} className="join-holder">
        <img
          width="29" height="29"
          src={require('../../assets/img/logo-short.svg')}
          alt=''
        />
        <div className="join-message">
          Willing to join Awesome Tutors?<br className="mobile" />{' '}
          <Link to="/become-a-tutor" className="join-link">Apply to become a tutor!</Link>
        </div>
      </Holder>
    </section>
  );
};
