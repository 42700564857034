// /study-help-3
export const feedbackItemsStudyHelp4 = [
  [
    {
      type: "C",
      message: "Your expert is gold! The dude broke down a tricky assignment topic for me in like 30 min.",
    },
    {
      type: "A",
      message: "Happy to hear that! How did he explain the topic to you?",
    },
    {
      type: "C",
      message: "Dunno, I guess he just knew how to make complicated stuff feel simple. He explained everything step by step, and I just got it, you know!",
    },
  ],
  [
    {
      type: "C",
      message: "Your expert Sheila was an absolute lifesaver in helping me grasp the assignment. She has this superpower of breaking down the task into manageable pieces, and suddenly, what seemed like a puzzle made sense! Please tell her that am so grateful for her excellent help with that assignment!",
    },
  ],
  [
    {
      type: "A",
      message: "Oh hi, Kayla! How'd you been?",
    },
    {
      type: "C",
      message: "I'm fine thanks. I just wanted to let you know that AT deserves a special shoutout on my graduation.",
    },
    {
      type: "C",
      message: "Seriously!! And Mary-Anne really helped me understand my assignments each time.",
    },
    {
      type: "C",
      message: "Can I send her a tip to say thanks?",
    },
  ],
  [
    {
      type: "A",
      message: "How did you like the help you received from your assigned expert? Let's say, on a scale from 1 to 5.",
    },
    {
      type: "C",
      message: "10! Jamie was of huge help in guiding me through data collection for my assignment. They showed me effective methods of collecting and analyzing the info. My project now feels like a breeze.",
    },
  ],
  [
    {
      type: "C",
      message: "The help I got from you was a game-changer for me!",
    },
    {
      type: "C",
      message: "Layla saved my day with her advice on a tricky assignment.",
    },
    {
      type: "C",
      message: "Plus, those alternative approaches she threw in? Brilliant move 🔥🔥",
    },
  ],
  [
    {
      type: "A",
      message: "How did your session go?",
    },
    {
      type: "C",
      message: "It was fantastic! Michael is friendly, kind, patient, and very smart, he's the best tutor ever❣",
    },
  ],
  [
    {
      type: "C",
      message: "Can you assign me to Colleen again, pls?",
    },
    {
      type: "C",
      message: "She was so good at guiding me through my assignment for my Econ class.",
    },
    {
      type: "C",
      message: "Sorry, I know it's last minute, but you guys never failed to help me! Hope you pull it for me one more time 🤞",
    },
  ],
  [
    {
      type: "C",
      message: "Thank youuuu!!! You guys totally helped my daughter get prepared for her finals this semester. I am recommending your website to all parents!!! Words cannot explain how thoughtful you guys were step-by-step helping solve all issues, setting the sessions, and finding the right tutors. And the reply time was amazing each time!",
    },
  ],
  [
    {
      type: "C",
      message: "Another service offered me to help with a discount. No way! You're the only ones I trust. And I will definitely recommend you to all of my college friends.",
    },
  ],
];
